import { useEffect } from "react";
import { Header } from "../../../components/Header";

import { Container, Grid, Main } from "./styles";

import { NProgressFunction } from "../../../hooks/useNprogress";

import { PeriodsTable } from "../../../components/PeriodsTable";

import { Sidebar } from "../../../components/AdminSidebar";

export function Periods() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Header/>

            <NProgressFunction/>

            <Main>
                <div className="welcome_message">
                    <h2 className="title">Gerenciar períodos</h2>
                    <p className="description">
                        Aqui é possível fazer o gerenciamento dos períodos e cursos cadastrados
                    </p>
                </div>

                <Grid>
                    <div className="sidebar">
                        <Sidebar/>
                    </div>

                    <div className="main">
                        <PeriodsTable/>
                    </div>
                </Grid>
            </Main>
        </Container>
    )
}