import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;

    background: var(--background-color);

    position: fixed;
    
    z-index: 10;

    top: 0;
    left: 0;
    right: 0;

    padding: 1rem;

    width: 100%;
    height: 100%;

    overflow: hidden;

    .loading { 
        display: flex;
        flex-direction: column;

        align-items: center;

        gap: 1.5rem;

        .title {
            font-family: 'Lexend', sans-serif;
            font-size: 1.3rem;
            font-weight: 400;
            color: var(--white-color);
        }
    
        position: relative;

        top: 15rem;
    }
`;