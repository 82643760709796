/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Header } from "../../components/Header";

import "../../assets/nprogress/nprogress.css";
import { NProgressFunction } from "../../hooks/useNprogress";

import { Container, Grid, Main, Section } from "./styles";

import { PeriodCard } from "../../components/PeriodCard";

import { api } from "../../services/axios";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";

import { ProfileCard } from "../../components/ProfileCard";

interface Period {
    id: string;
    name: string;
    type: "school" | "course";
    school_id: string;
    updated_at: Date;
    created_at: Date;
}

export function Dashboard() {
    const [period, setPeriod] = useState<Period | null>(null);
    const { user, setLoading } = useAuth();

    const firstname = `${user?.name.split(' ')[0]}` || 'Aluno';

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setLoading(true);

        const userData = async () => await api.get("/users/profile");
        
        userData()
            .then((response) => {
                const { data } = response;
                
                const period = data.period;

                setLoading(false);

                return setPeriod(period);
            })
            .catch(() => {
                setLoading(false);
                return toast.error("Você não está matrículado em nenhum período!")
            })
    }, []);
    
    return (
        <Container>
            <NProgressFunction/>
        
            <Header/>
            
            <Main>    
                <Section>
                    <div className="welcome_message">
                        <h2 className="title">Olá, {firstname}!</h2>

                        <p className="description">
                            { String(user?.roles) === "teacher" ? 
                            "Acesse suas salas no cartão abaixo e tenha acesso aos materiais que estão disponíveis aos seus alunos!" : 
                            "Acesse sua matrícula no cartão abaixo e desfrute dos conteúdos disponíveis para você!"
                            }
                        </p>
                    </div>

                    <Grid>
                        <section className="content">
                            <h1 className="content_title">{ String(user?.roles) === "teacher" ? "Salas cadastradas" : "Suas matrículas" }</h1>

                            <div className="content_cards">
                                {
                                    <PeriodCard id={period?.id} name={period?.name} type={period?.type} key={period?.id} />
                                }
                            </div>
                        </section>

                        <section className="profile">
                            <h1 className="profile_title">Seus dados</h1>  
                            <ProfileCard/>
                        </section>
                    </Grid>
                </Section>
            </Main>
        </Container>
    )
}