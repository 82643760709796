import styled from "styled-components";

export const Container = styled.div`
    min-height: 100vh;
    width: 100%;

    padding-top: 4.5rem;

    background: #1c2021;
    background-image: linear-gradient(rgb(14 13 13 / 30%),rgb(0 0 0 / 32%)),url(/assets/images/student.svg);
   
    object-fit: cover;
    background-size: cover;

    @media screen and (max-width: 768px) {
        height: 100%;
    }
`;

export const Main = styled.div`
    width: 100%;
    height: 100%;

    display: flex;

    @media screen and (max-width: 1144px) {
        height: 100%;

        display: flex;
    }
`;

export const Section = styled.section`
    max-width: 1440px;
    width: 100%;

    display: flex;

    padding: 2rem 3rem;

    .container {
        display: flex;
        justify-content: center;

        flex-direction: column;

        gap: 1rem;

        .container_title {
            font-family: 'Roboto', sans-serif;
        
            font-size: 1.5rem;
            font-weight: 500;

            line-height: 2rem;
            color: var(--white-color);

            line-height: 2.5rem;

            border-bottom: 3px solid var(--blue-color);

            width: 19rem;
        }
        
        .grade {
            display: flex;
            
            flex-wrap: wrap;

            gap: 1.3rem;

            transition: 1s ease;
        }
    }    
`;