import { useEffect, useState } from "react";
import { Container, Wrapper } from "./styles"

import { Header } from "../../../components/Header"

import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import { toast } from "react-toastify";

import { useNavigate, Link } from "react-router-dom";

import { api } from "../../../services/axios";
import { NProgressFunction } from "../../../hooks/useNprogress";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import Autocomplete from '@mui/material/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import validator from "validator";

interface IPeriods {
    id: string;
    school: {
        id: string;
    }
}

export function CreateUser() {
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
 
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
 
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');

    const [cpf, setCpf] = useState('');
    
    const [type, setType] = useState('student');
    const [first_access, setFirstAccess] = useState('sim');

    const [incorrectPass, setIncorrectPass] = useState(false);
    const [incorrectConfirmPass, setIncorrectConfirmPass] = useState(false);

    const [periods, setPeriods] = useState<IPeriods[]>([]);
    const [periodSelected, setPeriod] = useState<IPeriods | null>(null);
    
    useEffect(() => {
        const data = async () => await api.get('/periods');

        data()
            .then((response) => {
                const periods = response.data;

                setPeriods(periods);
            })
            .catch(() => {
                toast.error('Algo deu errado!');
            })
    }, []);

    const navigate = useNavigate();

    useEffect(() => {
        if(password) {
            if(password.length < 6) {
                return setIncorrectPass(true);
            } else {
                setIncorrectPass(false);
            }
    
            if(confirmPassword !== password) {
                return setIncorrectConfirmPass(true);
            } else {
                setIncorrectConfirmPass(false);
            };
        }
    }, [confirmPassword, password]);

    async function handleClick() {
        if(!userName) {
            return toast.error('Você precisa preencher o campo nome'); 
        }

        await api.post('/users', {
            name: userName,
            email,
            password,
            cpf,
            roles: type,
            school_id: periodSelected?.school?.id,
            period_id: periodSelected?.id,
            inactive: false,
            first_access: first_access === "sim" ? true : false,
        })

        .then(() => {
            toast.success('Usuário adicionado com sucesso!');
            return navigate('/users');
        })
        .catch((err) => {
            return toast.error(err.response.data.message);
        });
    }

    function setValidEmail(email: string) {
        if(validator.isEmail(email)) {
            return setEmail(email);
        };
    }

    function handleSelectType(event: React.ChangeEvent<HTMLInputElement>) {
        return setType((event.target as HTMLInputElement).value);
    }

    function handleFirstAccess(event: React.ChangeEvent<HTMLInputElement>) {
        return setFirstAccess((event.target as HTMLInputElement).value);
    }

    return (
        <Container>
            <Header/>

            <NProgressFunction/>

            <Wrapper>
                <div className="section">
                    <h2 className="title">Criar usuário</h2>
                    <p className="description">Preencha os campos abaixo para poder adicionar um novo usuário.</p>

                    <FormControl
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '1' },
                        }}
                        noValidate
                        autoComplete="off"
                        >
                        <TextField
                            id="outlined-name"
                            label="Nome"
                            value={userName}
                            onChange={event => setUserName(event.target.value)}
                            required
                        />

                        <TextField
                            id="input_signin"
                            label="E-mail"
                            fullWidth
                            type={"email"}
                            inputProps={{
                                inputMode: 'email'
                            }}
                            onChange={(event) => setValidEmail(event.target.value)}
                            required
                        />

                        <FormControl sx={{ width: 'full' }} variant="outlined">
                            <InputLabel htmlFor="standard-adornment-password">Senha</InputLabel>

                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                value={password ?? ''}
                                onChange={event => setPassword(event.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={event => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                autoComplete="off"
                                label="pass"
                                error={incorrectPass && true}
                                required
                            />
                            {
                                incorrectPass && (
                                    <>
                                        <p className="alert">A nova senha deve ter no mínimo 6 caracteres</p>
                                    </>
                                )
                            }
                        </FormControl>

                        <FormControl sx={{ width: 'full' }} variant="outlined">
                            <InputLabel htmlFor="standard-adornment-password">Repita a Senha</InputLabel>

                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={confirmPassword ?? ''}
                                onChange={event => setConfirmPassword(event.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={event => setShowConfirmPassword(!showConfirmPassword)}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                autoComplete="off"
                                label="confirm pass"
                                error={incorrectConfirmPass && true}
                                required
                            />
                        </FormControl>

                        <TextField
                            id="outlined-uncontrolled"
                            label="CPF"
                            placeholder="000.000.000-00"
                            onChange={event => setCpf(event.target.value)}
                            required
                        />

                        <Autocomplete
                            id="highlights-demo"
                            sx={{ width: 300 }}
                            options={periods}
                            getOptionLabel={(periods) => periods.id}
                            onChange={(event, period) => {
                                setPeriod(period);
                            }}
                            renderInput={(params) => (
                                <>
                                    <TextField {...params} label="Períodos" margin="normal" />
                                </>
                            )}
                            renderOption={(props, periods, { inputValue }) => {
                                const matches = match(periods.id, inputValue);
                                const parts = parse(periods.id, matches);

                                return (
                                    <li {...props}>
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight: part.highlight ? 700 : 400,
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                        />

                        <TextField
                            id="outlined-uncontrolled"
                            label="ID Escola"
                            value={ periodSelected ? String(periodSelected?.school?.id) : '' }
                            disabled
                        />

                        <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group">Tipo de usuário</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={type}
                                onChange={handleSelectType}
                            >
                                <FormControlLabel value="student" control={<Radio />} label="Estudante" />
                                <FormControlLabel value="teacher" control={<Radio />} label="Professor" />
                            </RadioGroup>
                        </FormControl>

                        <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group">Primeiro acesso?</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={first_access}
                                onChange={handleFirstAccess}
                            >
                                <FormControlLabel value="sim" control={<Radio />} label="Sim" />
                                <FormControlLabel value="nao" control={<Radio />} label="Não" />
                            </RadioGroup>
                        </FormControl>

                        <Button onClick={handleClick} variant="contained" style={{ height: '3.5rem' }} size="medium">
                            Salvar
                        </Button>

                        <Link to={'/users'} className="button">
                            Voltar
                        </Link>
                    </FormControl>
                </div>
            </Wrapper>
        </Container>
    )
}