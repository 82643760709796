import { Container, Section } from "./styles";
import { Header } from "../../components/Header/index";

import { Link } from "react-router-dom";

import { NProgressFunction } from "../../hooks/useNprogress";

export function NotFound() {
    return (
        <Container>
            <Header/>

            <NProgressFunction/>

            <Section>
                <div className="wrapper">
                    <div className="content">     
                        <img className="error_image" src="/assets/images/notes.svg" alt="not found"/>
                        
                        <h2 className="title">Tenho más notícias para você!</h2>
                        <p className="description">
                            A página que você está procurando pode ter sido removida ou está temporariamente indisponível.
                        </p>

                        <Link to={'/'} style={{ textDecoration: 'none' }}>
                            <button className="button">Voltar ao início</button>
                        </Link>
                    </div>
                </div>
            </Section>
        </Container>
    );
};