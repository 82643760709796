/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Header } from "../../components/Header";
import { ModuleCard } from "../../components/ModuleCard";

import { useAuth } from "../../hooks/useAuth";

import { NProgressFunction } from "../../hooks/useNprogress";

import { api } from "../../services/axios";

import { useNavigate } from "react-router-dom";

import {
    Container,
    Section,
    Main
} from "./styles";

interface IModules {
    id: string;
    name: string;
    slug: string;
    description: string;
    background_key: string;
    updated_at: Date;
    created_at: Date;
}

export function Modules() {
    const [modules, setModules] = useState<IModules[]>();

    const { setLoading } = useAuth();

    const navigator = useNavigate();

    useEffect(() => {
        setLoading(true);

        const modulesData = async () => await api.get<IModules[]>("/modules")
        
        modulesData()
            .then((response) => {
                const modulesData = response.data;

                setModules(modulesData);

                if(modulesData.length === 0) {
                    toast.warn("Não há módulos disponíveis para grade de seu período");
                    return navigator("/");
                }
            })
            .catch(() => {
                toast.error("Algo deu errado!");
            })

        setLoading(false);
    }, []);
    
    return (
        <Container>
            <Header/>

            <NProgressFunction/>

            <Main>
                <Section>
                    <div className="panel">
                        <div className="panel_box"></div>
                    </div>
                    <div className="container">
                        <h2 className="container_title">Módulos disponíveis</h2>
                        
                        <div className="grade">
                            {
                                modules?.map((modules) => (
                                    <ModuleCard 
                                        key={modules.id}
                                        id={modules.id}
                                        slug={modules.slug}
                                        name={modules.name}
                                        description={modules.description}
                                        background_key={modules.background_key}
                                        created_at={modules.created_at}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </Section>
            </Main>
        </Container>
    );
};