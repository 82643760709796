import { 
    createContext,
    ReactNode,
    ReactText,
    useEffect,
    useState,
} from "react";

import { api } from "../services/axios";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { User } from "../interfaces/IUser";

interface IUserResponse {
    user: User;
    token: string;
}

type signIn = {
    email: string;
    password: string;
}

interface AuthContextData {
    user: User | null;
    userRole: string;
    isLoading: boolean;
    signIn({ email, password}: signIn): Promise<ReactText | undefined>;
    signOut(): void;
    setLoading(value: boolean): void;
}

type AuthContextProps = {
    children: ReactNode;
}

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: AuthContextProps) {
    const [user, setUser] = useState<User | null>(null);
    const [userRole, setUserRole] = useState('guest');
    const [isLoading, setIsLoading] = useState(true);

    async function signIn({ email, password }: signIn) {
        try {
            await api.post<IUserResponse>('/sessions', {
                email,
                password
            })
            .then((response) => {
                const data = response.data;
                
                localStorage.setItem('@gen:token', data.token);
    
                api.defaults.headers.common.authorization = `Bearer ${data.token}`
    
                setUser({
                    id: data.user.id,
                    name: data.user.name,
                    roles: data.user.roles,
                    cpf: data.user.cpf,
                    email: data.user.email,
                    inactive: data.user.inactive,
                    avatar_key: data.user.avatar_key,
                    first_access: data.user.first_access,
                    period: data.user.period
                });
                
                if(data.user.first_access) {
                    toast.warn('Identificamos que esse é seu primeiro acesso, sendo assim você precisa alterar suas credenciais!');
                    return setUserRole("first_access");
                } else {
                    return setUserRole(String(data.user.roles));
                }
            }).catch(err => {
                toast.error(err.response.data.message);
            });
        } catch (err) {
            return toast.error('Algo deu errado!')
        }
    };

    function signOut(){
        localStorage.removeItem('@gen:token');

        setUser(null);
        
        setUserRole('guest');

        return toast.success('Sessão encerrada com sucesso!');
    };

    useEffect(() => {
        const token = localStorage.getItem('@gen:token');

        api.defaults.headers.common.authorization = `Bearer ${token}`;

        if(!token) {
            setIsLoading(false);
        }

        if(token){
            setIsLoading(true);
            const validateToken = async () => await api.get('/users/profile');
            
            validateToken()
                .then(response => {
                    const user = response.data;

                    setUser({
                        id: user.id,
                        name: user.name,
                        roles: user.roles,
                        avatar_key: user.avatar_key,
                        cpf: user.cpf,
                        email: user.email,
                        inactive: user.inactive,
                        first_access: user.first_access,
                        period: user.period,
                    });

                    setIsLoading(false);

                    if(user.first_access === true) {
                        toast.warn('Identificamos que esse é seu primeiro acesso, sendo assim você precisa alterar suas credenciais!');
                        return setUserRole("first_access");
                    } else {
                        return setUserRole(String(user.roles));
                    }
                })
                .catch(() => {
                    setUser(null);

                    localStorage.removeItem('@gen:token');

                    toast.error('Usuário não autenticado!');

                    return setIsLoading(false);
                });
        }
    }, []);

    function setLoading(value: boolean) {
        return setIsLoading(value);
    }

    return (
        <AuthContext.Provider value={{ user, userRole, isLoading, setLoading, signIn, signOut }}>
            { children }
        </AuthContext.Provider>
    )
};