import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }

    :root {
        --background-color: #05001e;
        --header-color: #1c2021;
        --blue-color: #20B2AA;
        --text-color: #3c4043;
        --title-color: #2f97ae;
        --white-color: #fff;
        --gray-color: #f2f4f7;
        --black-color: #131515;
        --red-color: #b81a1a;
        --orange-color: #faaa17;
        --button-color: #696b6e;
    }

    body, html {
        scroll-behavior: smooth;
    }

    body {
        background: var(--background-color);

        ::-webkit-scrollbar-track {
            border-radius: 3px;
            box-shadow: inset 0 0 3px #f7f8fa;
        }
        ::-webkit-scrollbar {
            width: 8px;
            height: 3px;
        }
        
        ::-webkit-scrollbar-thumb {
            background: #dee6e8;
            border-radius: 3px;
        }
    }
`;