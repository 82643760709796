import { Link } from "react-router-dom";
import { Container } from "./styles"

interface IProps {
    id?: string;
    name?: string;
    type?: "school" | "course";
}

export function PeriodCard(props: IProps) {
    return (
        <Container>
            <img src="/assets/images/students.svg" alt="students" />

            <span className="card_title">ID: <strong> { props.id }</strong></span>

            <span className="card_title">INFO: <strong> {props.name}</strong></span>
            <span className="card_type">Tipo de matrícula: <strong>{ props.type === "course" ? "curso" : "escolar"}</strong></span>

            <Link className="card_button" to={'/material'}>
                Acessar
            </Link>
        </Container>
    )
};