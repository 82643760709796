import { AuthProvider } from "./contexts/AuthContext";
import { GlobalProvider } from "./contexts/GlobalContext";

import { GlobalStyle } from "./styles/Global";
import { ToastContainer } from "react-toastify";

import { Router } from "./routes";

import VLibras from "@djpfs/react-vlibras";

function App() {
    return (
        <AuthProvider>
            <GlobalProvider>
                <Router/>

                <GlobalStyle/>

                <VLibras/>                
                
                <ToastContainer theme="light" style={{ padding: '1rem' }}/>
            </GlobalProvider>
        </AuthProvider>
    )
};

export { App };