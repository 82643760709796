import { useAuth } from "../hooks/useAuth";
import { Admin } from "./admin.routes";
import { Auth } from "./auth.routes";
import { Student } from "./student.routes";
import { Teacher } from "./teacher.routes";

import { Loading } from "../components/Loading";
import { FirstAccess } from "./first_access.routes";

export function Router() {
    const { userRole, isLoading } = useAuth();

    const routes = () => { 
            switch (userRole) {
                case "admin":
                    return (<Admin/>);
                case "student":
                    return (<Student/>)
                case "teacher":
                    return (<Teacher/>)
                case "guest":
                    return (<Auth/>)
                case "first_access":
                    return (<FirstAccess/>)
        }
    }

    return (
        <>
            { isLoading && <Loading/> }
            { userRole && routes() }
        </>
    );
};