import { useGlobal } from "../../hooks/useGlobal";
import { Container, Wrapper } from "./styles";

interface IProps {
    name: string;
    description: string;
    created_at: Date;
}

export function ModuleInfoModal({
    name,
    description,
    created_at
}: IProps) {
    const { isShowModuleInfo } = useGlobal();

    function handleClose() {
        return isShowModuleInfo();
    }

    return (
        <Container>
            <Wrapper>
                <div className="card">
                    <div className="card_data">
                        <h2 className="name">{ name }</h2>

                        <p className="description">
                            { description }
                        </p>
                    </div>

                    <div className="divider"></div>

                    <div className="card_info">
                        <span className="text">Publicação: <strong>{ new Date(created_at).toDateString() }</strong></span>
                    </div>

                    <button onClick={handleClose} className="button">Fechar</button>
                </div>
            </Wrapper>
        </Container>
    )
}