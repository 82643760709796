import { useEffect } from "react";

import { Header } from "../../components/Header";
import { Container } from "./styles";

import "../../assets/nprogress/nprogress.css";
import { NProgressFunction } from "../../hooks/useNprogress";

export function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <NProgressFunction/>

            <Header/>

            <Container>
                <div className="wrapper">
                    <h1 className="title">Política de Privacidade</h1>

                    <p className="content">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni nesciunt, a laudantium commodi, modi sed voluptate
                        ipsum sapiente ut dignissimos sint id nulla voluptatibus ipsa tempora consequuntur exercitationem? Minima, facilis.
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni nesciunt, a laudantium commodi, modi sed voluptate
                        ipsum sapiente ut dignissimos sint id nulla voluptatibus ipsa tempora consequuntur exercitationem? Minima, facilis.
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni nesciunt, a laudantium commodi, modi sed voluptate
                        ipsum sapiente ut dignissimos sint id nulla voluptatibus ipsa tempora consequuntur exercitationem? Minima, facilis.
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni nesciunt, a laudantium commodi, modi sed voluptate
                        ipsum sapiente ut dignissimos sint id nulla voluptatibus ipsa tempora consequuntur exercitationem? Minima, facilis.
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni nesciunt, a laudantium commodi, modi sed voluptate
                        ipsum sapiente ut dignissimos sint id nulla voluptatibus ipsa tempora consequuntur exercitationem? Minima, facilis.
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni nesciunt, a laudantium commodi, modi sed voluptate
                        ipsum sapiente ut dignissimos sint id nulla voluptatibus ipsa tempora consequuntur exercitationem? Minima, facilis.
                    </p>
                </div>
            </Container>
        </>
    )
}