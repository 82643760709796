import { Header } from "../../../components/Header"

import { Container, Main, Grid } from "./styles";

import { Sidebar } from "../../../components/AdminSidebar";

import { NProgressFunction } from "../../../hooks/useNprogress";
import { UsersTable } from "../../../components/UsersTable";
import { useAuth } from "../../../hooks/useAuth";

export function AdminDashboard() {
    const { user } = useAuth();

    const firstname = `${user?.name.split(' ')[0]}` || 'Aluno';

    return (
        <Container>
            <Header/>

            <NProgressFunction/>

            <Main>
                <div className="welcome_message">
                    <h2 className="title">Olá, {firstname}!</h2>
                    <p className="description">
                        Painel administrativo da Genminando, aqui é possível fazer o gerenciamento da aplicação.
                    </p>
                </div>

                <Grid>
                    <div className="sidebar">
                        <Sidebar/>
                    </div>

                    <div className="main">
                        <UsersTable/>
                    </div>
                </Grid>
            </Main>
        </Container>
    )
}