import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Dashboard } from "../pages/Dashboard";
import { PrivacyPolicy } from "../pages/PrivacyPolicy";
import { Profile } from "../pages/Profile";
import { NotFound } from "../pages/NotFound/index";
import { MainContent } from "../pages/MainContent";
import { Annotations } from "../pages/Annotations";
import { ViewPDF } from "../pages/ViewPdf";
import { ChangeUserPassword } from "../pages/ChangeUserPassword";
import { DownloadMaterial } from "../pages/Download";
import { Modules } from "../pages/Modules";
import { ViewModule } from "../pages/ViewModule";

export function Teacher() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Dashboard/>}/>
                <Route path="/privacy" element={<PrivacyPolicy/>}/>
                <Route path="/material" element={<MainContent/>}/>
                <Route path="/anotacoes" element={<Annotations/>}/>
                <Route path="/perfil" element={<Profile/>}/>
                <Route path="/view/:id" element={<ViewPDF/>}/>
                <Route path="/material/download/:file_key" element={<DownloadMaterial/>}/>
                <Route path="/change-password" element={<ChangeUserPassword/>}/>
                <Route path="/modulos" element={<Modules/>}/>
                <Route path="/modulo/:slug" element={<ViewModule />} />
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </BrowserRouter>
    );
};