import styled from "styled-components";

export const Container = styled.div`
    min-height: 100vh;
    width: 100%;

    background-image: linear-gradient(rgb(14 13 13 / 30%),rgb(0 0 0 / 32%)),url(/assets/images/students.svg);
    object-fit: cover;
    background-size: cover;

    padding-top: 4.5rem;

    @media screen and (max-width: 768px) {
        height: 100%;
    }
`;

export const Main = styled.div`
    max-width: 1440px;
    width: 100%;

    display: flex;
    justify-content: center;

    margin: 0 auto;

    padding: 2rem;

    gap: 1rem;

    @media screen and (max-width: 968px) {
        max-width: 25rem;

        display: flex;
        flex-direction: column;
    }
`;

export const Section = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;

    padding: 3rem 3.5rem;

    border: 0.0625rem solid #dadce0;
    border-radius: 0.8rem;

    background: var(--gray-color);

    gap: 0.7rem;

    .profile {
        display: flex;
        justify-content: center;
        flex-direction: column;

        align-items: center;

        gap: 1rem;

        .title {
            font-family: 'Lexend', sans-serif;
            font-size: 1.5rem;
            font-weight: 500;
            color: var(--text-color);
        }

        .description {
            font-family: 'Lexend', sans-serif;
            font-size: 1rem;
            font-weight: 300;
            color: var(--text-color);

            text-align: center;
        }
    }

    
    .buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;

        gap: 0.5rem;

        .button {
            &:hover {
                filter: brightness(0.9);
                transition: 0.5s ease;
            }
        }
    }

    @media screen and (max-width: 768px) {
        height: 100%;
        width: 100%;

        display: flex;
        justify-content: center;

        border-radius: 0.3rem;

        .title {
            font-size: 1rem;
        }

        .description {
            font-size: 1rem;
        }

        .buttons {
            display: flex;
            justify-content: center;
            flex-direction: column;

            gap: 0.5rem;

            .button {
                font-size: 0.9rem;
            }
        }
    }

    @media screen and (max-width: 768px) {
        height: 100%;
        width: 100%;

        display: flex;
        justify-content: center;
    }
`;