import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AnnotationCard } from "../../components/AnnotationCard";

import { Header } from "../../components/Header";
import { NProgressFunction } from "../../hooks/useNprogress";
import { api } from "../../services/axios";
import { Container, Section } from "./styles";

import AddIcon from '@mui/icons-material/Add';
import { useAuth } from "../../hooks/useAuth";
import { Link } from "react-router-dom";

interface IAnnotation {
    id: string;
    title: string;
    content: string;
}

export function Annotations() {
    const [annotations, setAnnotations] = useState<IAnnotation[]>();

    const { setLoading } = useAuth();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const userData = async () => await api.get<IAnnotation[]>("/annotations");
        
        userData()
            .then((response) => {
                const annotation = response.data;

                if(!annotation) {
                    toast.warn('Você ainda não criou nenhuma anotação!');
                }

                setAnnotations(annotation);
            })
            .catch(() => {
                toast.error("Algo deu errado!")
            })

        setLoading(false);
    }, [setLoading]);

    return (
        <Container>
            <NProgressFunction/>

            <Header/>

            <Section>
                    <div className="welcome_message">
                        <h2 className="title">Minhas anotações</h2>
                        <p className="description">Aqui abaixo estão todas as anotações criadas por você!</p>
                    </div>

                    <section className="content">
                        <h1 className="content_title">Suas anotações</h1>

                        <div className="content_cards">
                            {
                                annotations?.map((annotation) => (
                                    <AnnotationCard 
                                        id={annotation.id}
                                        title={annotation.title}
                                        content={annotation.content}
                                        key={annotation.id}
                                    />
                                ))
                            }

                            {
                                annotations?.length === 0 && (
                                    <Link onClick={() => toast.warn('Você precisa escolher um material para fazer uma anotação')} to={'/material'}>
                                        <div className="content_add">
                                            <AddIcon style={{ fontSize: 30, color: '#20B2AA' }}/>
                                        </div>
                                    </Link>
                                )
                            }

                        </div>
                    </section>
            </Section>
        </Container>
    )
};