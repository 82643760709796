import { Container } from "./styles";

import { Link } from "react-router-dom";

import InfoIcon from '@mui/icons-material/Info';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

import { useGlobal } from "../../hooks/useGlobal";
import { ModuleInfoModal } from "../ModuleInfoModal";

interface IProps {
    id: string;
    name: string;
    slug: string;
    description: string;
    background_key: string;
    created_at: Date;
}

export function ModuleCard({ 
    id,
    name,
    description,
    slug,
    background_key,
    created_at
}: IProps) {
    const { showModuleInfo, isShowModuleInfo } = useGlobal();

    function handleAboutButtonClick() {
        return isShowModuleInfo();
    }

    return (
        <>
            <Container background={ background_key ? `${process.env.REACT_APP_CDN}/images/${background_key}` : '/assets/images/student.svg' }>
                <div className="content_info">
                    <span className="name">{ name }</span>
                
                    <p className="description">
                        { description }
                    </p>
                </div>

                <div className="buttons">
                    <Link className="card_button" to={`/modulo/${slug}`}>
                        Abrir <FolderOpenIcon style={{ fontSize: '1.3rem' }}/>
                    </Link>

                    <button onClick={handleAboutButtonClick} className="card_button black">
                        Sobre <InfoIcon style={{ fontSize: '1.3rem' }}/>
                    </button>
                </div>
            </Container>

            { 
                showModuleInfo && <ModuleInfoModal key={id} name={name} description={description} created_at={created_at}/>
            }
        </>
    );
};