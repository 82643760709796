import { useEffect } from "react";
import { Header } from "../../../components/Header";

import { Container, Main, Grid } from "./styles";

import { NProgressFunction } from "../../../hooks/useNprogress";

import { SchoolsTable } from "../../../components/SchoolsTable";

import { Sidebar } from "../../../components/AdminSidebar";

export function Schools() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Header/>

            <NProgressFunction/>

            <Main>
                <div className="welcome_message">
                    <h2 className="title">Gerenciar escolas</h2>
                    <p className="description">
                        Aqui é possível fazer o gerenciamento das escolas cadastradas
                    </p>
                </div>

                <Grid>
                    <div className="sidebar">
                        <Sidebar/>
                    </div>

                    <div className="main">
                        <SchoolsTable/>
                    </div>
                </Grid>
            </Main>
        </Container>
    )
}