import {
    useState,
    useEffect,
    useCallback,
    useMemo
} from 'react';

import { Link } from "react-router-dom";

import {
    DataGrid,
    GridToolbarExport,
    GridEditRowsModel,
    GridActionsCellItem,
    GridRowId,
} from '@mui/x-data-grid';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchIcon from '@mui/icons-material/Launch';
import AddIcon from '@mui/icons-material/Add';

import { toast } from "react-toastify";

import { api } from '../../services/axios';

import Button from '@mui/material/Button';

function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
            }}
        >
            <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Pesquisar…"
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
                sx={{
                    width: {
                        xs: 1,
                        sm: 'auto',
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    '& .MuiSvgIcon-root': {
                        mr: 0.5,
                    },
                    '& .MuiInput-underline:before': {
                        borderBottom: 1,
                        borderColor: 'divider',
                    },
                }}
            />

            <GridToolbarExport style={{ padding: '0.6rem' }} csvOptions={{
                fields: [
                    'id',
                    'name',
                    'description',
                    'period_id',
                    'module_id'
                ],
            }} />

            <Link style={{ textDecoration: 'none' }} to={'/upload/material'}>
                <Button variant="text">
                    <AddIcon/>
                    Adicionar
                </Button>
            </Link>
        </Box>
    );
}

export function MaterialsTable() {
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState([]);
    const [search, setSearch] = useState(false);
    const [editRowsModel, setEditRowsModel] = useState({});
    const [editRowData, setEditRowData] = useState<any>({});

    useEffect(() => {
        const data = async () => await api.get('/materials/listall');

        data()
            .then((response) => {
                const materials = response.data;

                setRows(materials);
            })
            .catch(() => {
                toast.error('Algo deu errado!');
            })
    }, [search]);

    useEffect(() => {
        if(editRowData.id) {
            const updateData = async () => await api.put('/materials', {
                id: editRowData.id.value,
                name: editRowData.name.value,
                description: editRowData.description.value,
                period_id: editRowData.period_id.value,
                module_id: editRowData.module_id.value
            });
    
            updateData()
                .then(() => toast.success('Material atualizado com sucesso!'))
                .catch((err) => {
                    toast.error(err.response.data.message);
                });
        }

    }, [editRowData]);

    const deleteMaterial = useCallback(
        (id: GridRowId) => async () => {
            await api.delete(`/materials/${id}`)
                .then(() => {
                    toast.success(`Material ${id} deletado com sucesso!`);
                    setSearch(true);
                })
                .catch(() => {
                    toast.error('Algo deu errado');
                })
        },
        [],
    );

    const columns = useMemo(
        () => [
            {
                field: 'id',
                headerName: 'ID',
                width: 150,
                editable: true,
            },
            {
                field: 'name',
                headerName: 'Nome',
                width: 150,
                editable: true,
            },
            {
                field: 'description',
                headerName: 'Descrição',
                width: 150,
                editable: true,
            },
            {
                field: 'period_id',
                headerName: 'ID Período',
                type: 'string',
                width: 150,
                editable: true,
            },
            {
                field: 'module_id',
                headerName: 'ID Módulo',
                type: 'string',
                width: 150,
                editable: true,
            },
            {
                field: 'actions',
                type: 'actions',
                width: 80,
                getActions: (params: any) => [
                    <Link to={`/view/${params.id}`} target="_blank"> 
                        <GridActionsCellItem
                            icon={<LaunchIcon/>}
                            label="Abrir"
                        />
                    </Link>,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={deleteMaterial(params.id)}
                    />
                ],
            },
        ],
        [deleteMaterial],
    );

    const requestSearch = (searchValue: string) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = rows.filter((row: any) => {
            return Object.keys(row).some((field: any) => {
                return searchRegex.test(row[field]);
            });
        });

        setRows(filteredRows);
    };

    function clearSearch() {
        setSearchText('');
        return setSearch(!search);
    }

    const handleEditRowsModelChange = useCallback((model: GridEditRowsModel) => {
        const rows = Object.keys(model);

        if(rows.length === 0) {
            return;
        } else {
            setEditRowData(model[rows[0]]);
        };
        
        setEditRowsModel(model);
    }, []);

    return (
        <Box sx={{ height: 450, width: 1, background: '#fff', borderRadius: '0.3rem' }}>
            <DataGrid
                components={{ Toolbar: QuickSearchToolbar }}
                rows={rows}
                columns={columns}
                componentsProps={{
                    toolbar: {
                        value: searchText,
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                            requestSearch(event.target.value),
                        clearSearch: () => clearSearch(),
                    },
                }}
                localeText={{
                    toolbarExport: 'Exportar',
                    toolbarExportCSV: 'Baixar csv',
                    toolbarExportPrint: 'Imprimir',
                    noRowsLabel: 'Não há colunas',
                }}
                editRowsModel={editRowsModel}
                editMode="row"
                onEditRowsModelChange={handleEditRowsModelChange}
            />
        </Box>
    );
}