import styled from "styled-components";

export const Container = styled.div`
    max-width: 50rem;
    height: 26.8rem;
    
    display: flex;
    justify-content: space-between;
    
    margin-top: 1.3rem;
    
    align-items: center;

    background: var(--gray-color);
    border-radius: 0.4rem;

    transition: 1s ease;

    padding: 2rem 6.5rem;

    gap: 2rem;

    border-top: 4px solid var(--blue-color);

    .content_profile {
        display: flex;
        flex-direction: column;
        justify-content: center;

        align-items: center;

        gap: 1.3rem;

        .title {
            font-family: 'Lexend', sans-serif;
            font-size: 2rem;
            font-weight: 400;
            color: var(--text-color);
        }

        .wrapper {
            position: relative;

            top: 0;
            left: 0;
            right: 0;

            border-radius: 10rem;

            border: 0.3rem solid #bdbdbd;

            .avatar_image {
                width: 100%;
                height: 100%;
            }
        }

        .button {
            display: flex;
            justify-content: center;

            align-items: center;

            text-align: center;

            padding: 0.8rem 1.1rem 0.8rem;

            border-style: none;
            border-radius: 0.2rem;

            background: var(--button-color);
            color: var(--white-color);
            
            font-family: 'Lexend', sans-serif;
            font-weight: 500;
            font-size: 1rem;

            cursor: pointer;

            &:hover {
                filter: brightness(0.9);
                background: var(--blue-color);
            }
        }

        @media screen and (max-width: 1080px) {
            .button {
                padding: 0.9rem 0.7rem;
                gap: 0.2rem;

                font-size: 1rem;

                text-align: center;
            }
        }
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        padding: 1.5rem 2.4rem;
        
        border-left: 2px solid #dadfd3e6;

        .container_info {
            display: flex;
            flex-direction: column;

            gap: 1.2rem;

            label {
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 1.4rem;

                color: var(--text-color);

                line-height: 1rem;
            }
    
            p {
                font-family: 'Lexend', sans-serif;
                font-weight: 300;
                color: var(--text-color);
                font-size: 1rem;
            }
        }
    }

    @media screen and (max-width: 1140px) {
        max-width: 17rem;
        height: 20rem;

        display: flex;
        flex-direction: column;

        align-items: center;

        padding: 1rem 2rem;

        .content_profile .title {
            font-size: 1.5rem;
        }
        
        .container {
            display: none;
        }
    }
`;