import { BrowserRouter, Routes, Route } from "react-router-dom";
import { NotFound } from "../pages/NotFound";

import { PrivacyPolicy } from "../pages/PrivacyPolicy";
import { ForgotPassword } from "../pages/ForgotPassword";
import { SignIn } from "../pages/SignIn";
import { ResetForgotPassword } from "../pages/ResetForgotPassword";

export function Auth() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<SignIn/>}/>
                <Route path="/privacy" element={<PrivacyPolicy/>}/>
                <Route path="/forgot" element={<ForgotPassword/>} />
                <Route path="/forgot/new-password/:token" element={<ResetForgotPassword/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </BrowserRouter>
    );
};