import { useEffect } from "react";
import { Header } from "../../../components/Header"

import { Container, Main } from "./styles";

import { NProgressFunction } from "../../../hooks/useNprogress";
import { UploadPdfCard } from "../../../components/UploadPdfCard";

export function CreateMaterial() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Header/>

            <NProgressFunction/>

            <Main>
                <div className="welcome_message">
                    <h1 className="title">Criar materiais</h1>
                    <p className="description">
                        Aqui é possível fazer o envio de novos materiais para plataforma
                    </p>
                </div>

                <div className="main">
                    <UploadPdfCard/>
                </div>
            </Main>
        </Container>
    )
}