import styled from "styled-components";

export const Container = styled.section`
    height: 100vh;

    display: flex;

    background: var(--gray-color);

    .wrapper {
        max-width: 1440px;
        width: 100%;
        
        display: flex;
        flex-direction: column;

        margin: 0 auto;

        padding: 2rem 3rem;

        gap: 1rem;

        .title {
            font-family: 'Roboto', sans-serif;
            font-size: 1.5rem;
            font-weight: 400;
        }

        .content {
            font-family: 'Lexend', sans-serif;
            font-size: 1rem;
            font-weight: 300;

            line-height: 1.5rem;
        }
    }

    @media screen and (max-width: 768px) {
        height: 100%;

        display: flex;
        justify-content: center;
    }
`;