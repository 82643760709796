import { useEffect } from "react";
import { Container } from "./styles";

import { useAuth } from "../../hooks/useAuth";

import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';

import { Link } from "react-router-dom";

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import KeyIcon from '@mui/icons-material/Key';
import { useGlobal } from "../../hooks/useGlobal";

export function ProfileNavigation() {
    const { user } = useAuth();

    const { isChangeAvatar, avatarModal } = useGlobal();

    useEffect(() => {
        function scrollHidden() {
            if (!avatarModal) 
                return document.body.style.overflow = 'unset';
    
            return document.body.style.overflow = 'hidden';
        }

        scrollHidden();
    }, [avatarModal]);

    return (
        <Container>
            <div className="aside_profile">
                <div className= "wrapper">
                    <Avatar style={{ cursor: 'pointer', width: 130, height: 130, fontSize: '1.5rem' }}sx={{ bgcolor: deepOrange[500] }}>
                        { !user?.avatar_key ? `${user?.name[0]}` : <img className="avatar" src={`${process.env.REACT_APP_CDN}/avatar/${user?.avatar_key}`} alt="avatar" /> }
                    </Avatar>
                </div>

                <h3 className="name">{ user?.name.split(" ")[0] }</h3>

                <button className="button" onClick={isChangeAvatar}>
                    <ManageAccountsIcon/>
                    Alterar avatar
                </button>

                <Link style={{ textDecoration: 'none' }} to={'/change-password'}>
                    <button className="button">
                        <KeyIcon/>
                        Alterar senha
                    </button>
                </Link>
            </div>
        </Container>
    )
};