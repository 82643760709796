import styled, { keyframes } from "styled-components";

const showContent = keyframes`
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const Container = styled.section`
    min-height: 100vh;

    display: flex;
    flex-direction: column;

    background-image: linear-gradient(rgb(14 13 13 / 30%),rgb(0 0 0 / 32%)),url(/assets/images/banner.jpg);
    object-fit: cover;
    background-size: cover;

    padding-top: 4.5rem;

    @media screen and (max-width: 768px) {
        height: 100%;
    }
`;

export const Main = styled.section`
    height: 100%;

    display: flex;
    justify-content: center;

    .wrapper {
        max-width: 54rem;
        height: 16rem;

        background: var(--gray-color);
        box-shadow: 10px 3px 20px rgb(58 58 1 / 10%);

        margin: 1rem;

        display: flex;
        flex-direction: column;

        border: 0.0625rem solid #dadce0;
        border-radius: 0.8rem;

        opacity: 0;
        animation: ${showContent} 0.3s forwards ease;

        gap: 0.5rem;

        padding: 3rem;

        background: var(--gray-color);

        .title {
            font-family: 'Roboto', sans-serif;
            font-size: 1.5rem;
            color: var(--text-color);
            font-weight: 300;
        }
        
        .description {
            font-family: 'Lexend', sans-serif;
            font-size: 1rem;
            color: var(--text-color);
            font-weight: 300;
        }
    }

    .alert {
        display: flex;
        justify-content: center;

        margin-top: 1rem;
    }

    @media screen and (max-width: 768px) {
        .wrapper {
            height: 23rem;
        }
    }
`;

export const Form = styled.form`
    display: flex;
    justify-content: center;

    margin-top: 1rem;

    gap: 0.5rem;

    .button {
        width: 8rem;

        background-color: #2fb1de;

        padding: 1rem;
        
        box-shadow: none;
        
        &:hover {
            background-color: var(--button-color);
        }
    }
    
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;

        .button {
            padding: 0.8rem;
        }
    }
`;