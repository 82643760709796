import styled from "styled-components";

export const Container = styled.div<{ background: string }>`
    width: 36rem;
    height: 21rem;

    display: flex;
    justify-content: space-between;

    background: #fff;

    border-radius: 0.5rem;

    box-shadow: 10px 3px 20px rgb(58 58 1 / 20%);

    transition: 0.3s ease;

    .content_info {
        width: 20rem;

        display: flex;
        justify-content: center;
        flex-direction: column;

        padding: 2rem 3rem;

        background: #72A0C1;

        background-image: linear-gradient(rgb(14 13 13 / 40%),rgb(0 0 0 / 80%)), url(${props => props.background});
        object-fit: cover;
        background-size: cover;

        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;

        gap: 0.6rem;

        .name {
            font-family: 'Roboto', sans-serif;
            font-size: 1.2rem;
            font-weight: 500;
            color: var(--white-color);
            line-height: 1.3rem;
            text-overflow: ellipsis;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
        }

        .description {
            font-family: 'Lexend', sans-serif;
            font-size: 1rem;
            font-weight: 300;
            color: var(--white-color);
            line-height: 1.3rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
        }
    }

    .buttons {
        display: flex;
        justify-content: center;
        flex-direction: column;

        gap: 1rem;

        padding: 3rem;

        .card_button {
            width: 10rem;
            height: 3rem;
    
            display: flex;
            justify-content: center;
    
            gap: 0.4rem;
    
            align-items: center;
    
            border-style: none;
            border-radius: 0.5rem;
    
            background: var(--blue-color);
            color: var(--white-color);
            
            font-family: 'Lexend', sans-serif;
            font-weight: 500;
            font-size: 1rem;
    
            text-decoration: none;
    
            cursor: pointer;
    
            &:hover {
                filter: brightness(0.9);
            }
        }
        
        .black {
            background: rgba(0, 0, 0, 0.89);
        }
    }

    &:hover {
        transition: 0.4s ease;
    }

    @media screen and (max-width: 1144px) {
        max-width: 18rem;
        height: 26rem;

        display: flex;
        justify-content: center;
        flex-direction: column;

        border-radius: 0.4rem;
        
        .content_info {
            width: 100%;
            height: 100%;

            padding: 2rem 2.5rem;

            display: flex;
            justify-content: center;
            flex-direction: column;

            border-top-left-radius: 0.3rem;
            border-top-right-radius: 0.3rem;
            border-bottom-left-radius: 0;

            .name {
                font-size: 1rem;
            }
        }

        .buttons {
            width: 100%;
            max-height: 20rem;

            border-radius: 0;

            display: flex;
            justify-content: center;

            padding: 2rem 2rem;

            .card_button {
                width: 100%;
                font-size: 0.8rem;

                border-radius: 0.3rem;
            }
        }
    }
`;