import { useState } from "react";
import { HeaderContainer, HeaderStyle } from "./styles";

import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

import { styled } from '@mui/material/styles';

import Avatar from '@mui/material/Avatar';
import { deepOrange, blue } from '@mui/material/colors';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FolderIcon from '@mui/icons-material/Folder';
import SourceIcon from '@mui/icons-material/Source';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import Badge from '@mui/material/Badge';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';

import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        transform: 'scale(.3)',
        border: '1px solid currentColor',
        content: '""',
    },
}));

export function Header() {
    const { user, signOut } = useAuth();

    const [showHamburguer, setShowHambuguer] = useState(false);

    function handleHamburguer() {
        return setShowHambuguer(!showHamburguer);
    }

    return (
        <HeaderContainer>
            <HeaderStyle>
                <div className="header_logo">
                    <img className="logo" src="/assets/images/logo.png" alt="logo" />
                    
                    <Link style={{ textDecoration: 'none' }} to={'/'}>
                        <h1 className="title">Genminando</h1>
                    </Link>

                    <button onClick={handleHamburguer} className="menu_hamburguer">
                        {
                            !showHamburguer ? (
                                <MenuIcon style={{ fontSize: '2.5rem' }} />
                            ) : (
                                <CloseIcon style={{ fontSize: '2.5rem' }} />
                            )
                        }
                    </button>
                </div>

                {
                    showHamburguer && (
                        <div className="mobile_nav">
                            <div className="wrapper">
                                <Link to={'/'} className="title">
                                    Início
                                </Link>

                                {
                                    user && (
                                        <>
                                            {
                                                String(user.roles) === "student" || String(user.roles) === "teacher" ? (
                                                    <>
                                                        <Link to={'/material'} className="title">
                                                            Materiais
                                                        </Link>

                                                        <Link to={'/modulos'} className="title">
                                                            Módulos
                                                        </Link>
                                                    </>
                                                ) : null
                                            }

                                            {
                                                String(user.roles) === "admin" && (
                                                    <>
                                                        <Link to={'/users'} className="title">
                                                            Usuários
                                                        </Link>

                                                        <Link to={'/material'} className="title">
                                                            Materiais
                                                        </Link>
                                                    </>
                                                )
                                            }

                                        </>
                                    )
                                }
                                {
                                    !user && (
                                        <a href="https://linkr.bio/genmais.br" className="title">
                                            Contato
                                        </a>
                                    )
                                }                                              
                            </div>
                        </div>
                    )
                }

                <nav className="nav_center">
                    <Link to={'/'} className="nav_title">
                        Início
                    </Link>
                    {
                        user && (
                            <>
                                {
                                    String(user.roles) === "student" || String(user.roles) === "teacher" ? (
                                        <>
                                            <Link to={'/material'} className="nav_title">
                                                Materiais
                                            </Link>

                                            <Link to={'/modulos'} className="nav_title">
                                                Módulos
                                            </Link>
                                        </>
                                    ) : null
                                }

                                {
                                    String(user.roles) === "admin" && (
                                        <>
                                            <Link to={'/users'} className="nav_title">
                                                Usuários
                                            </Link>

                                            <Link to={'/material'} className="nav_title">
                                                Materiais
                                            </Link>
                                        </>
                                    )
                                }
                            </>
                        )
                    }

                    {
                        !user && (
                            <a href="https://linkr.bio/genmais.br" className="nav_title">
                                Contato
                            </a>
                        )
                    }
                </nav>

                {
                    user && (
                        <div className="header_nav">
                            {
                                String(user.roles) === "student" || String(user.roles) === "teacher" ? (
                                    <>
                                        <Link to={'/material'}>
                                            <Avatar>
                                                <PictureAsPdfIcon />
                                            </Avatar>
                                        </Link>

                                        <Link className="annotations_icon" to={'/anotacoes'}>
                                            <Avatar sx={{ bgcolor: blue[500] }}>
                                                <AssignmentIcon />
                                            </Avatar>
                                        </Link>
                                    </>
                                ) : null
                            }

                            {
                                String(user.roles) === "admin" && (
                                    <Link to={'/upload/material'}>
                                        <Avatar>
                                            <CloudUploadIcon />
                                        </Avatar>
                                    </Link>
                                )
                            }

                            <PopupState variant="popper" popupId="demo-popup-menu">
                                {(popupState) => (
                                    <>
                                        <StyledBadge
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            variant="dot"
                                        >
                                            <Avatar style={{ cursor: 'pointer' }} {...bindTrigger(popupState)} sx={{ bgcolor: deepOrange[500] }}>
                                                { !user?.avatar_key ? `${user?.name[0]}` : <img className="avatar" src={`${process.env.REACT_APP_CDN}/avatar/${user?.avatar_key}`} alt="avatar" /> }
                                            </Avatar>
                                        </StyledBadge>

                                        <Menu
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "right",
                                            }} 
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            {...bindMenu(popupState)}>

                                            <Link style={{ textDecoration: 'none', color: '#2c2e30' }} to={'/perfil'}>
                                                <MenuItem onClick={popupState.close}>
                                                    <ListItemIcon>
                                                        <ManageAccountsIcon />
                                                    </ListItemIcon>
                                                    Minha conta
                                                </MenuItem>
                                            </Link>

                                            {
                                                String(user.roles) === "student" && (
                                                    <div>
                                                        <Link style={{ textDecoration: 'none', color: '#2c2e30' }} to={'/'}>
                                                            <MenuItem onClick={popupState.close}>
                                                                <ListItemIcon>
                                                                    <FolderIcon />
                                                                </ListItemIcon>
                                                                Minhas matrículas
                                                            </MenuItem>
                                                        </Link>

                                                        <Link style={{ textDecoration: 'none', color: '#2c2e30' }} to={'/modulos'}>
                                                            <MenuItem onClick={popupState.close}>
                                                                <ListItemIcon>
                                                                    <SourceIcon />
                                                                </ListItemIcon>
                                                                Meus módulos
                                                            </MenuItem>
                                                        </Link>

                                                        <Link style={{ textDecoration: 'none', color: '#2c2e30' }} to={'/material'}>
                                                            <MenuItem onClick={popupState.close}>
                                                                <ListItemIcon>
                                                                    <PictureAsPdfIcon />
                                                                </ListItemIcon>
                                                                Meus materiais
                                                            </MenuItem>
                                                        </Link>

                                                        <Link style={{ textDecoration: 'none', color: '#2c2e30' }} to={'/anotacoes'}>
                                                            <MenuItem onClick={popupState.close}>
                                                                <ListItemIcon>
                                                                    <AssignmentIcon />
                                                                </ListItemIcon>
                                                                Minhas anotações
                                                            </MenuItem>
                                                        </Link>
                                                    </div>
                                                )
                                            }

                                            {
                                                String(user.roles) === "teacher" && (
                                                    <div>
                                                        <Link style={{ textDecoration: 'none', color: '#2c2e30' }} to={'/'}>
                                                            <MenuItem onClick={popupState.close}>
                                                                <ListItemIcon>
                                                                    <FolderIcon />
                                                                </ListItemIcon>
                                                                Minhas salas
                                                            </MenuItem>
                                                        </Link>

                                                        <Link style={{ textDecoration: 'none', color: '#2c2e30' }} to={'/modulos'}>
                                                            <MenuItem onClick={popupState.close}>
                                                                <ListItemIcon>
                                                                    <SourceIcon />
                                                                </ListItemIcon>
                                                                Meus módulos
                                                            </MenuItem>
                                                        </Link>

                                                        <Link style={{ textDecoration: 'none', color: '#2c2e30' }} to={'/material'}>
                                                            <MenuItem onClick={popupState.close}>
                                                                <ListItemIcon>
                                                                    <PictureAsPdfIcon />
                                                                </ListItemIcon>
                                                                Meus materiais
                                                            </MenuItem>
                                                        </Link>

                                                        <Link style={{ textDecoration: 'none', color: '#2c2e30' }} to={'/anotacoes'}>
                                                            <MenuItem onClick={popupState.close}>
                                                                <ListItemIcon>
                                                                    <AssignmentIcon />
                                                                </ListItemIcon>
                                                                Minhas anotações
                                                            </MenuItem>
                                                        </Link>
                                                    </div>
                                                )
                                            }

                                            {
                                                String(user.roles) === "admin" && (
                                                    <div>
                                                        <Link style={{ textDecoration: 'none', color: '#2c2e30' }} to={'/upload/material'}>
                                                            <MenuItem onClick={popupState.close}>
                                                                <ListItemIcon>
                                                                    <PictureAsPdfIcon />
                                                                </ListItemIcon>
                                                                Importar materiais
                                                            </MenuItem>
                                                        </Link>

                                                        <Link style={{ textDecoration: 'none', color: '#2c2e30' }} to={'/users'}>
                                                            <MenuItem onClick={popupState.close}>
                                                                <ListItemIcon>
                                                                    <AssignmentIcon />
                                                                </ListItemIcon>
                                                                Importar usuários
                                                            </MenuItem>
                                                        </Link>

                                                        <Link style={{ textDecoration: 'none', color: '#2c2e30' }} to={'/material'}>
                                                            <MenuItem onClick={popupState.close}>
                                                                <ListItemIcon>
                                                                    <AssignmentIcon />
                                                                </ListItemIcon>
                                                                Materiais
                                                            </MenuItem>
                                                        </Link>

                                                        <Link style={{ textDecoration: 'none', color: '#2c2e30' }} to={'/modulos'}>
                                                            <MenuItem onClick={popupState.close}>
                                                                <ListItemIcon>
                                                                    <AssignmentIcon />
                                                                </ListItemIcon>
                                                                Modulos
                                                            </MenuItem>
                                                        </Link>

                                                        <Link style={{ textDecoration: 'none', color: '#2c2e30' }} to={'/periods'}>
                                                            <MenuItem onClick={popupState.close}>
                                                                <ListItemIcon>
                                                                    <AssignmentIcon />
                                                                </ListItemIcon>
                                                                Períodos
                                                            </MenuItem>
                                                        </Link>

                                                        <Link style={{ textDecoration: 'none', color: '#2c2e30' }} to={'/schools'}>
                                                            <MenuItem onClick={popupState.close}>
                                                                <ListItemIcon>
                                                                    <AssignmentIcon />
                                                                </ListItemIcon>
                                                                Escolas
                                                            </MenuItem>
                                                        </Link>
                                                    </div>
                                                )
                                            }

                                            <Link style={{ textDecoration: 'none', color: '#2c2e30' }} to={'/'}>
                                                <MenuItem onClick={signOut}>
                                                    <ListItemIcon>
                                                        <Logout fontSize="small" />
                                                    </ListItemIcon>
                                                    Sair
                                                </MenuItem>
                                            </Link>
                                        </Menu>
                                    </>
                                )}
                            </PopupState>
                        </div>
                    )
                }
            </HeaderStyle>
        </HeaderContainer>
    );
};