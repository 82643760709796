import { useEffect } from "react";
import { Header } from "../../../components/Header";

import { Container, Main, Grid } from "./styles";

import { NProgressFunction } from "../../../hooks/useNprogress";

import { UsersTable } from "../../../components/UsersTable";
import { ImportUsersCard } from "../../../components/ImportUsersCard";

export function ImportUsers() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Header/>

            <NProgressFunction/>

            <Main>
                <div className="welcome_message">
                    <h2 className="title">Gerenciar usuários</h2>
                    <p className="description">
                        Aqui é possível fazer o gerenciamento dos usuários cadastrados
                    </p>
                </div>

                <Grid>
                    <div className="sidebar">
                        <ImportUsersCard/>
                    </div>

                    <div className="main">
                        <UsersTable/>
                    </div>
                </Grid>
            </Main>
        </Container>
    )
}