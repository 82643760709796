import { useState, useRef } from "react";
import { useGlobal } from "../../hooks/useGlobal";

import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";

import { Container, Wrapper } from "./styles";

import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import { NProgressFunction } from "../../hooks/useNprogress";

import { toast } from "react-toastify";
import { api } from "../../services/axios";

const convertBase64ToBlob = async (base64: string) => {
    const response = await fetch(base64);
    const blob = await response.blob();
    return blob;
  };

export function UploadAvatarModel () {
    const [avatar, setAvatar] = useState<File | null>(null);
    const [imageData, setImageData] = useState<Cropper>();

    const file = new FormData();

    const { isChangeAvatar } = useGlobal();

    async function handleUploadAvatar() {
        const image = imageData?.getCroppedCanvas().toDataURL();

        if(image) {
            const blob = await convertBase64ToBlob(image);

            file.append("avatar", blob, "image/jpg");
        }

        const data = async () => await api.post('/users/avatar', file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        
        toast.promise(data, {
            pending: 'Carregando..',
            success: 'Avatar atualizado com sucesso',
            error: 'Algo deu errado',
        })

        data()
            .then(() => {
                file.delete("avatar");

                setAvatar(null);

                isChangeAvatar()

                return window.location.reload();
            })
            .catch(() => {
                file.delete("avatar");

                setAvatar(null);

                isChangeAvatar()

                return setAvatar(null);
            })
    }

    function handleSelectAvatar(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        if(!e.target.files) {
            toast.error('Você precisa selecionar uma imagem para continuar');
        };

        setAvatar(e.target.files![0]);
    }

    const cropperRef = useRef<HTMLImageElement>(null);

    const onCrop = () => {
        const imageElement: any = cropperRef?.current;
        const cropper: any = imageElement?.cropper;
        cropper.getCroppedCanvas().toDataURL();
    };

  return (
        <>
            <NProgressFunction/>

            <Container>
                <Wrapper>
                    {
                        !avatar ? (
                            <div className="drop_image">
                                <h2 className="title">Selecione uma imagem do seu dispositivo</h2>

                                <p className="p">Somente serão aceitos arquivos nos formatos: png e jpg</p>

                                <label className="button import">
                                    <input onChange={handleSelectAvatar} id="inputImage" type="file" accept="image/*" />

                                    <ImageSearchIcon/>
                                    Selecionar imagem
                                </label>

                                <button onClick={isChangeAvatar} className="button">Voltar</button>
                            </div> 
                        ) : ( 
                            <div className="drop_image">
                                <h2 className="title">Personalize a imagem selecionada:</h2>
                    
                                <Cropper
                                    src={URL.createObjectURL(avatar)}
                                    style={{ height: 350, width: "100%" }}
                                    initialAspectRatio={4 / 4}
                                    aspectRatio={4 / 4}
                                    guides={false}
                                    crop={onCrop}
                                    ref={cropperRef}
                                    checkCrossOrigin={false}
                                    onInitialized={(instance) => {
                                        setImageData(instance)
                                    }}
                                />
                    
                                <button className="button save" onClick={handleUploadAvatar}>Salvar</button>
                                <button onClick={isChangeAvatar} className="button">Voltar</button>
                            </div>
                        )
                    }
                </Wrapper>
            </Container>
        </>
    );
}