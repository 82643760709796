import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    background: var(--white-color);

    gap: 1.5rem;

    border-radius: 0.4rem;

    border-top: 4px solid var(--blue-color);

    width: 17rem;
    height: 27rem;

    padding: 1rem 2rem;

    transition: 0.3s ease;

    .card_title {
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
        color: var(--text-color);
        line-height: 2rem;

        border-bottom: 1px solid var(--blue-color);

        strong {
            font-family: 'Lexend', sans-serif;
            font-weight: 300;
            font-size: 1rem;
        }
    }

    .card_type {
        font-family: 'Lexend', sans-serif;
        font-size: 1rem;

        font-weight: 300;
        text-align: start;

        border-radius: 0.2rem;

        color: var(--text-color);
        
        strong {
            background: var(--blue-color);
            border-radius: 0.3rem;
            padding: 0.2rem;
            
            color: var(--white-color);
            text-transform: lowercase;
        }
    }

    .card_button {
        display: flex;
        justify-content: center;

        gap: 0.4rem;

        align-items: center;

        padding: 0.8rem 2rem;

        border-style: none;
        border-radius: 0.2rem;

        background: var(--button-color);
        color: var(--white-color);
        
        font-family: 'Lexend', sans-serif;
        font-weight: 500;
        font-size: 1rem;

        text-decoration: none;

        cursor: pointer;

        &:hover {
            background: var(--blue-color);
            filter: brightness(0.9);
        }
    }
    
    .download {
        background: var(--red-color);
    }

    &:hover {
        transition: 0.4s ease;
    }

    @media screen and (min-width: 1440px) {
        display: flex;

        max-width: 20rem;
    }

    @media screen and (max-width: 1380px) {
        display: flex;

        max-width: 19rem;
    }

    @media screen and (max-width: 1080px) {
        display: flex;

        max-width: 17rem;
    }

    @media screen and (max-width: 968px) {
        display: flex;

        max-width: 20rem;
        
        padding: 1rem 1.7rem;
    }
`;