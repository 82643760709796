import { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";

import { toast } from "react-toastify";

import { Container, Main } from "./styles";

import { Header } from "../../components/Header/index";

import { NProgressFunction } from "../../hooks/useNprogress";

import DownloadIcon from '@mui/icons-material/Download';

import { api } from "../../services/axios";

import { useAuth } from "../../hooks/useAuth";

export function DownloadMaterial() {
    const [fileUrl, setFileUrl] = useState('');

    const { file_key } = useParams();
    const navigate = useNavigate();

    const { setLoading } = useAuth();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setLoading(true);

        const generateFileUrl = async () => await api.get(`/materials/download/${file_key}`)
            .then((response) => {
                const { file_url } = response.data;

                return setFileUrl(file_url);
            })
            .catch(() => {
                toast.error('Algo deu errado');
                return navigate('/material');
            });

        generateFileUrl()
        setLoading(false);
    }, [setLoading, file_key, navigate])

    return (
        <Container>
            <Header/>

            <NProgressFunction/>

            <Main>
                <div className="wrapper">
                    <div className="content">
                        <h2 className="title">📌 Parece que você deseja efetuar o download de um material</h2>

                        <img className="logo" src="/assets/images/student.svg" alt="genminando"/>

                        <p className="description">
                            Geramos um link de download temporário para você, clique no botão abaixo e
                            tenha acesso ao material em seu dispositivo.
                        </p>

                        <div className="buttons">    
                            <a className="button download" href={fileUrl} target="_blank" rel="noreferrer">
                                Baixar <DownloadIcon style={{ fontSize: '1.3rem' }}/>
                            </a>

                            <Link to={'/material'} className="button">
                                Voltar
                            </Link>
                        </div>
                    </div>
                </div>
            </Main>
        </Container>
    );
};