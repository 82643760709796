import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { 
    Container,
    Main,
    Aside
} from "./styles";

import { styled } from '@mui/material/styles';

import { api } from "../../services/axios";
import { toast } from "react-toastify";

import { Header } from "../../components/Header";
import { MaterialGrade } from "../../components/ModuleGrade";

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

interface IMaterial {
    id: string;
    name: string;
    description: string;
}

interface IModule {
    id: string;
    name: string;
    description: string;
    background_key: string;
    materials: IMaterial[];
    updated_at: Date;
};

export function ViewModule() {
    const [moduleData, setModule] = useState<IModule | undefined>();

    const { slug } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        const modulesData = async () => await api.get<IModule>(`/modules/view/${slug}`);

        modulesData()
            .then((response) => {
                const data = response.data;

                return setModule(data);
            })
            .catch(() => {
                toast.error("Algo deu errado!");
                return navigate("/modulos");
            })
    }, []);

    return (
        <Container>
            <Header/>

            <Main>
                <Aside background={ moduleData?.background_key ? `${process.env.REACT_APP_CDN}/images/${moduleData.background_key}` : '/assets/images/banner.jpg' }>
                    <div className="aside__header">
                        <h1 className="name">
                            { moduleData?.name }
                        </h1>
                    </div>

                    <div className="aside__container">
                        <h2 className="title">{ moduleData?.name }</h2>
                        <p className="description">
                            { moduleData?.description }
                        </p>

                        <div className="divider"></div>

                        <span className="progress">Seu progresso:</span>
                        <BorderLinearProgress variant="determinate" value={1} />

                        <div className="divider"></div>

                        <span className="date"><strong>Atualizado em: </strong> { new Date(String(moduleData?.updated_at)).toDateString() }</span>
                    </div>
                </Aside>

                <MaterialGrade module_name={moduleData?.name} material={moduleData?.materials} />
            </Main>
        </Container>
    )
}