import styled from "styled-components";

export const Container = styled.div`
    width: 40rem;
    height: 100%;
    min-height: 20rem;

    display: flex;
    flex-direction: column;

    background-color: var(--gray-color);

    border-radius: 0.5rem;

    align-items: center;

    .side__header {
        width: 100%;
        height: 4rem;
        
        display: flex;
        justify-content: center;

        align-items: center;

        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;

        background: var(--orange-color);

        .name {
            font-family: 'Roboto';
            color: var(--white-color);
            font-weight: 300;
        }
    }

    ul, li {
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        flex-direction: column;

        gap: 0.5rem;
        list-style: none;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        height: 100%;

        display: flex;
    }
`;

export const Nav = styled.nav`
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;

    padding: 1rem;

    gap: 1rem;

    .side__item {
        width: 100%;
        height: 4rem;

        display: flex;
        justify-content: space-between;
        
        align-items: center;

        padding: 1.5rem;

        gap: 1rem;

        background: #272727;

        border-radius: 0.3rem;
    }

    .item__title {
        font-family: 'Lexend';
        font-weight: 300;
        font-size: 1.2rem;
        color: var(--white-color);
    }

    .button {
        max-width: 6rem;
        height: 2.5rem;

        display: flex;
        justify-content: center;
        
        align-items: center;

        text-decoration: none;

        padding: 1rem;

        gap: 0.4rem;

        text-align: center;

        border-style: none;
        border-radius: 0.3rem;

        background: var(--button-color);
        color: var(--white-color);
        
        font-family: 'Lexend', sans-serif;
        font-weight: 500;
        font-size: 1rem;

        cursor: pointer;

        &:hover {
            filter: brightness(0.9);
        }
    }
`;