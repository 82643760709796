import styled from "styled-components";

export const Container = styled.div`
    min-height: 100vh;
    width: 100%;

    background-image: linear-gradient(rgb(14 13 13 / 30%),rgb(0 0 0 / 32%)),url(/assets/images/students.svg);
    object-fit: cover;
    background-size: cover;

    padding-top: 4.5rem;

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

export const Section = styled.section`
    max-width: 1440px;
    width: 100%;
    
    margin: 0 auto;

    display: flex;
    flex-direction: column;

    padding: 1rem 2rem;
    
    .welcome_message {
        display: flex;
        flex-direction: column;

        align-items: center;

        gap: 0.7rem;

        padding: 2rem 3rem;

        .title {
            font-family: 'Lexend', sans-serif;
            font-size: 1.5rem;
            font-weight: 400;
            color: var(--white-color);
        }

        .description {
            font-family: 'Lexend', sans-serif;
            font-size: 1rem;

            font-weight: 300;
            text-align: start;
            
            line-height: 1.4rem;

            color: var(--gray-color);
        }

        @media screen and (max-width: 768px) {
            display: flex;
            justify-content: center;

            padding: 1.8rem 3rem;

            .description {
                width: 20rem;
                text-align: center;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;

        gap: 1rem;

        transition: 1s ease;

        .content_title {
            font-family: 'Roboto', sans-serif;
            
            font-size: 1.5rem;
            font-weight: 500;

            line-height: 2rem;

            border-bottom: 3px solid var(--blue-color);
            width: 14rem;
            padding-bottom: 0.5rem;
            margin-bottom: 0.2rem;
            
            color: var(--white-color);
        }

        .content_cards {
            display: flex;
            flex-wrap: wrap;

            gap: 1rem;
        
            .content_add {
                display: flex;
                justify-content: center;

                background: var(--gray-color);

                border-top: 4px solid var(--blue-color);

                gap: 1.4rem;

                border-radius: 0.4rem;

                width: 14rem;
                height: 5rem;

                cursor: pointer;

                padding: 1.2rem 3rem;

                transition: 0.3s ease;

                &:hover {
                    transform: scale(1.02);
                    transition: 0.3s ease;
                }
            }
        }
    }

    @media screen and (max-width: 1440px) {
        padding: 1rem 2rem;

        transition: 0.3s ease;
    }

    @media screen and (max-width: 968px) {
        .content {
            display: flex;
            justify-content: center;
        }
    }
    
    @media screen and (max-width: 768px) {
        display: flex;

        gap: 1rem;

        padding: 1rem 3rem;

        .welcome_message {
            display: flex;
            justify-content: center;
            
            align-items: center;

            padding: 1.8rem 1.5rem;
        }

        .content {
            display: flex;
            justify-content: center;
            flex-direction: column;

            align-items: center;

            .content_cards {
                display: flex;
                justify-content: center;
                flex-direction: column;
                
                .content_add {
                   width: 14rem;
                   height: 4rem;
                }

            }
        }
    }
`;