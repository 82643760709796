import { useEffect, useState } from "react";
import { Container, Wrapper } from "./styles"

import { Header } from "../../../components/Header"

import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import { toast } from "react-toastify";

import { useNavigate, Link } from "react-router-dom";

import { api } from "../../../services/axios";
import { NProgressFunction } from "../../../hooks/useNprogress";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Autocomplete from '@mui/material/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

interface ISchools {
    id: string;
    name: string;
}

export function CreatePeriod() {
    const [schoolName, setSchoolName] = useState('');
    const [schoolId, setSchoolId] = useState<String | undefined>('');
    const [type, setType] = useState('school');

    const [schools, setSchools] = useState<ISchools[]>([]);

    useEffect(() => {
        const data = async () => await api.get('/schools')

        data()
            .then((response) => {
                const schools = response.data;
                
                setSchools(schools);
            })
            .catch(() => {
                toast.error('Algo deu errado');
            })

    }, [])

    const navigate = useNavigate();

    async function handleClick() {
        if(!schoolName) {
            return toast('Você precisa preencher todos os campos')
        }

        if(!schoolId) {
            return toast('Você precisa preencher todos os campos')
        }

        await api.post('/periods', {
            name: schoolName,
            type,
            school_id: schoolId,
        })

        .then(() => {
            toast.success('Período adicionado com sucesso!');
            return navigate('/periods');
        })
        .catch((err) => {
            return toast.error(err.response.data.message);
        });
    }

    function handleSelectType(event: React.ChangeEvent<HTMLInputElement>) {
        return setType((event.target as HTMLInputElement).value);
    }

    return (
        <Container>
            <Header/>

            <NProgressFunction/>

            <Wrapper>
                <div className="section">
                    <h2 className="title">Criar período</h2>
                    <p className="description">Preencha os campos abaixo para poder adicionar um novo período.</p>

                    <FormControl
                        component="form"
                        style={{ width: '100%' }}
                        sx={{
                            '& > :not(style)': { m: 1, width: '1' },
                        }}
                        noValidate
                        autoComplete="off"
                        >
                        <TextField
                            id="outlined-name"
                            label="Nome"
                            value={schoolName}
                            onChange={event => setSchoolName(event.target.value)}
                            required
                        />

                        <Autocomplete
                            id="highlights-demo"
                            sx={{ width: 300 }}
                            options={schools}
                            getOptionLabel={(school) => school.id}
                            onChange={(event, school) => {
                                setSchoolId(school?.id);
                            }}
                            renderInput={(params) => (
                                <>
                                    <TextField {...params} label="Escolas" margin="normal" />
                                </>
                            )}
                            renderOption={(props, periods, { inputValue }) => {
                                const matches = match(periods.id, inputValue);
                                const parts = parse(periods.id, matches);

                                return (
                                    <li {...props}>
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight: part.highlight ? 700 : 400,
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                        />

                        <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group">Tipo de período</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={type}
                                onChange={handleSelectType}
                            >
                                <FormControlLabel value="school" control={<Radio />} label="Escola" />
                                <FormControlLabel value="course" control={<Radio />} label="Curso" />
                            </RadioGroup>
                        </FormControl>

                        <Button onClick={handleClick} variant="contained" style={{ height: '3.5rem' }} size="medium">
                            Salvar
                        </Button>

                        <Link to={'/periods'} className="button">
                            Voltar
                        </Link>
                    </FormControl>
                </div>
            </Wrapper>
        </Container>
    )
}