import styled from "styled-components";

export const HeaderContainer = styled.header`
    height: 4.5rem;

    background: var(--white-color);

    position: fixed;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 10;

    box-shadow: 10px 3px 20px rgb(58 58 1 / 20%);

    display: flex;
    justify-content: space-between;
`;

export const HeaderStyle = styled.div`
    margin: 0 auto;
    width: 1440px;

    display: flex;
    justify-content: space-between;

    padding: 1rem 2rem;

    align-items: center;

    border-bottom: 1px solid #e6e8eb;

    a {
        &:hover {
            filter: opacity(0.9);
        }
    }

    .header_logo {
        display: flex;
        justify-content: space-between;
        
        align-items: center;

        gap: 0.4rem;

        .logo {
            width: 2rem;
            clip-path: circle();
        }

        .title {
            font-family: 'Lexend', sans-serif;
            font-size: 1.4rem;
            color: var(--title-color);
            font-weight: 400;
        }

        .menu_hamburguer {
            display: none;
        }
    }

    .mobile_nav {
        display: none;

        align-items: center;

        border-radius: 4px;
        box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
        position: absolute;
        min-width: 19rem;
        min-height: 8rem;
        max-width: calc(100% - 32px);
        max-height: calc(100% - 40px);
        outline: 0;
        background: var(--white-color);
        top: 4rem;

        padding: 1.4rem;
        
        transition: 0.7s ease;
    }

    .wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;

        gap: 1rem;

        .title {
            border-left: 2px solid var(--blue-color);

            font-family: 'Lexend', sans-serif;
            font-size: 1rem;
            font-weight: 500;

            color: var(--text-color);

            text-decoration: none;

            padding-left: 0.5rem;

            &:hover {
                filter: opacity(0.9);
                transition: 0.2s ease;
            }
        }
    }

    .header_nav {
        display: flex;
        justify-content: space-between;

        align-items: center;

        gap: 1rem;

        .avatar {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .nav_center {
        height: 100%;
        width: 100%;

        display: flex;
        justify-content: center;

        gap: 1.5rem;

        align-items: center;

        position: absolute;

        top: 0;
        left: 0;

        .nav_title {
            font-family: 'Lexend', sans-serif;
            color: var(--text-color);
            font-size: 1.3rem;
            font-weight: 500;
            
            display: flex;
            align-items: center;

            height: 100%;
            position: relative;

            text-decoration: none;
            transition: color 0.2s ease 0s, border-color 0.2s ease 0s;

            cursor: pointer;

            &:hover {
                border-bottom: 2px solid var(--blue-color);
                filter: opacity(0.9);
                transition: width 1s ease;
            }
        }
    }

    @media screen and (max-width: 760px) {
        display: flex;
        justify-content: space-between;

        align-items: center;

        gap: 0.5rem;

        .header_logo {
            .logo {
                max-width: 2.5rem;
                width: 2.5rem;
            }

            .title {
                display: none;
            }

            .menu_hamburguer {
                display: block;
                border-style: none;

                background: transparent;
                border-style: none;
                cursor: pointer;

                margin-top: 0.2rem;

                &:hover {
                    filter: opacity(0.9);
                }

                &:active {
                    transition: 0.3s ease;
                }
            }
        }

        .mobile_nav {
            display: flex;
        }

        .nav_center {
            display: none;
        }

        .header_nav {
            .annotations_icon {
                display: none;
            }
        }
    }
`;