import { Container, Nav } from "./styles";

import { Link } from "react-router-dom";

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

interface IMaterial {
    id: string;
    name: string;
    description: string;
}

interface IProps {
    module_name?: string;
    material?: IMaterial[];
}

export function MaterialGrade({ module_name, material }: IProps) {
    return (
        <Container>
            <div className="side__header">
                <h2 className="name">{ module_name }</h2>
            </div>

            <Nav>
                <ul>
                    {
                        material?.map((material) => (
                                <li key={material.id}>
                                    <div className="side__item">
                                        <PictureAsPdfIcon style={{ color: '#fff' }}/>
                                        <span className="item__title">
                                            {material.name}
                                        </span>
                                        <Link className="button" to={`/view/${material.id}`}>Abrir</Link>
                                    </div>
                                </li>
                            )
                        )
                    }
                </ul>
            </Nav>
        </Container>
    )
}