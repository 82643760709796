import styled from "styled-components";

export const Container = styled.div`
    height: 100vh;

    align-items: center;

    position: fixed;

    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    background: #000000b0;

    z-index: 12;
`;

export const Wrapper = styled.div`
    max-width: 1440px;

    display: flex;
    justify-content: center;
    flex-direction: column;

    padding: 2rem;

    align-items: center;

    gap: 1rem;

    .drop_image {
        max-width: 40rem;
        max-height: 100%;

        display: flex;
        flex-direction: column;

        border-radius: 0.5rem;

        padding: 3rem;

        gap: 1rem;

        background: var(--gray-color);
        
        .title {
            font-family: 'Lexend', sans-serif;
            font-size: 1.5rem;
            font-weight: 400;
            color: var(--text-color);
        }

        .p {
            font-family: 'Lexend', sans-serif;
            font-size: 1rem;
            font-weight: 300;
            color: var(--text-color);
        }
    
        input {
            display: none;
        }
        
        .button {
            display: flex;
            justify-content: center;
    
            align-items: center;
    
            text-align: center;
    
            padding: 0.8rem 1.1rem 0.8rem;
    
            gap: 0.4rem;
    
            border-style: none;
            border-radius: 0.2rem;
    
            background: var(--button-color);
            color: var(--white-color);
            
            font-family: 'Lexend', sans-serif;
            font-weight: 500;
            font-size: 1rem;
    
            cursor: pointer;

            &:hover {
                filter: brightness(0.9);
            }
        }
    
        .import {
            background: var(--blue-color);
        }

        .save {
            background: var(--blue-color);
        }
    }

    @media screen and (max-width: 768px) {
        display: flex;

        padding: 1rem;
    }
`;