import styled from "styled-components";

export const Container = styled.div`
    min-height: 100vh;
    width: 100%;
    
    background: #1c2021;

    padding-top: 4.5rem;

    @media screen and (max-width: 768px) {
        height: 100%;
    }
`;

export const Section = styled.div`
    height: 100%;

    display: flex;
    justify-content: center;

    .wrapper {
        height: 100%;
        max-width: 1440px;

        margin: 0 auto;

        margin-top: 1.5rem;

        .content {
            display: flex;
            flex-direction: column;

            align-items: center;

            border: 0.0625rem solid #dadce0;
            border-radius: 0.8rem;

            padding: 2rem;

            .error_image {
                padding: 2rem;
                max-width: 22rem;
            }
    
            .title {
                font-family: 'Roboto', sans-serif;
                font-size: 1rem;
                font-weight: 300;
                line-height: 1.8rem;
                color: var(--white-color);
            }
    
            .description {
                font-family: 'Lexend', sans-serif;
                font-size: 1rem;
                font-weight: 300;
                color: var(--white-color);
                max-width: 30rem;
            }
    
            .button {
                display: flex;
                justify-content: center;
                
                padding: 1rem;
                margin-top: 1.5rem;
    
                border-style: none;
                border-radius: 0.2rem;
    
                border: 1px solid var(--button-color);
                
                color: var(--gray-color);
                background: #12121291;
                
                font-family: 'Lexend', sans-serif;
                font-weight: 500;
                font-size: 1rem;
    
                cursor: pointer;
    
                &:hover {
                    filter: brightness(0.9);
                }
            }
        }

        @media screen and (max-width: 768px) {
            .content {
                display: flex;
                justify-content: center;

                max-width: 22rem;

                padding: 2rem 4rem;

                .error_image {
                    max-width: 20rem;
                }
            }
        }
    }
`;