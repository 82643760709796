import {
    Container,
    Form,
    Wrapper,
} from "./styles";

import { FormEvent, useState } from "react";

import "../../assets/nprogress/nprogress.css";
import { NProgressFunction } from "../../hooks/useNprogress";

import { useAuth } from "../../hooks/useAuth";
import { useGlobal } from "../../hooks/useGlobal";
import { TawkMessager } from "../../hooks/useTawk";

import { TextField } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

import FormControl from '@mui/material/FormControl';

import { Link } from "react-router-dom";

import { toast } from "react-toastify";

import validator from "validator";

export function SignIn() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const { signIn } = useAuth();
    const { isSign, isSignInLoading } = useGlobal();

    async function handleLogin(event: FormEvent) {
        event.preventDefault();

        isSign(true);

        if (!email.trim()) {
            isSign(false);
            return toast('Você precisa digitar seu e-mail!');
        }

        if (!password.trim()) {
            isSign(false);
            return toast('Você precisa digitar sua senha!');
        }

        await signIn({ email, password });
        
        return isSign(false);
    }

    function setValidEmail(email: string) {
        if(validator.isEmail(email)) {
            return setEmail(email);
        };
    }

    function handlePassword(event: FormEvent) {
        event.preventDefault();

        if(email) {
           return setIsValidEmail(true);
        }
        
        return toast("Precisamos do seu e-mail para continuar");
    }

    return (
        <>
            <NProgressFunction />

            <Container>
                <Wrapper>
                    {
                        !isValidEmail ? (
                            <>
                                <Form onSubmit={handlePassword}>
                                    <img className="logo" src="/assets/images/logo.png" alt="genminando"/>

                                    <h1 className="form__title">Bem-vindo(a) à Genminando.</h1>

                                    <label>
                                        <span>Insira seu e-mail para continuar.</span>

                                        <TextField
                                            id="input_signin"
                                            label="E-mail"
                                            fullWidth
                                            type={"email"}
                                            inputProps={{
                                                inputMode: 'email'
                                            }}
                                            onChange={(event) => setValidEmail(event.target.value)}
                                        />
                                    </label>

                                    <button className="button" type="submit">
                                        {
                                            !isSignInLoading ? "Continuar " : <CircularProgress style={{ color: '#fff', gap: '1rem' }} size={20} disableShrink />
                                        }
                                    </button>
                                </Form>
                            </>
                        ) : (
                            <>
                                <Form>
                                    <img className="logo" src="/assets/images/logo.png" alt="genminando"/>

                                    <h1 className="form__title">Bem-vindo(a) à Genminando.</h1>

                                    <label>
                                        <span>Insira a sua senha para continuar</span>
                                        <FormControl sx={{ width: 'full' }} variant="outlined">
                                            <InputLabel htmlFor="standard-adornment-password">Senha</InputLabel>

                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={password ?? ''}
                                                onChange={event => setPassword(event.target.value)}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={event => setShowPassword(!showPassword)}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                autoComplete="off"
                                                label="pass"
                                            />
                                        </FormControl>
                                    </label>

                                    <button className="button" type="submit" onClick={handleLogin}>
                                        {
                                            !isSignInLoading ? "Entrar " : <CircularProgress style={{ color: '#fff', gap: '1rem' }} size={20} disableShrink />
                                        }
                                    </button>
                                    
                                    <button className="button back" onClick={() => setIsValidEmail(false)}>Voltar</button>

                                    <Link className="forgot_password" to={'/forgot'}>
                                        Esqueci minha senha
                                    </Link>
                                </Form>
                            </>
                        )
                    }
                </Wrapper>
            </Container>

            <TawkMessager/>
        </>
    )
};