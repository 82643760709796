import styled from "styled-components";

export const Container = styled.div`
    min-height: 100vh;

    background-image: linear-gradient(rgb(14 13 13 / 30%),rgb(0 0 0 / 32%)),url(/assets/images/students.svg);
    object-fit: cover;
    background-size: cover;

    padding-top: 4.5rem;

    @media screen and (max-width: 768px) {
        height: 100%;
    }
`;

export const Main = styled.div`
    height: 100%;
    width: 100%;
`;

export const Wrapper = styled.div`
    max-width: 1440px;
    width: 100%;
    
    display: flex;
    justify-content: center;

    margin: 0 auto;

    padding: 1.5rem;

    .form {
        display: flex;
        flex-direction: column;
        justify-content: center;

        align-items: center;

        padding: 2rem 4rem;

        border: 0.0625rem solid #dadce0;
        border-radius: 0.8rem;

        box-shadow: 10px 3px 20px rgb(58 58 1 / 10%);

        background: var(--gray-color);

        max-width: 33rem;
        height: 33rem;

        gap: 0.5rem;

        .title {
            font-family: 'Lexend', sans-serif;
            font-size: 1.5rem;
            font-weight: 500;
            color: var(--text-color);
        }

        .description {
            font-family: 'Lexend', sans-serif;
            font-size: 1rem;
            font-weight: 300;
            color: var(--text-color);

            text-align: center;
        }

        .button {
            display: flex;
            justify-content: center;
            
            padding: 1.1rem;

            border-style: none;
            border-radius: 0.2rem;

            text-decoration: none;

            background-color: var(--button-color);
            color: var(--white-color);
            
            font-family: 'Lexend', sans-serif;
            font-weight: 500;
            font-size: 1rem;

            cursor: pointer;

            &:hover {
                filter: brightness(0.9);
            }
        }

        .alert {
            font-family: 'Lexend', sans-serif;
            font-weight: 300;
            font-size: 0.8rem;
            margin-top: 0.5rem;
            color: var(--text-color);
        }

        .save {
            text-transform: none;

            transition: 0.3s;

            &:hover {
                filter: brightness(0.9);
            }
        }

        @media screen and (max-width: 768px) {
            height: 100%;
            max-width: 100%;

            display: flex;
            justify-content: center;
            padding: 3rem 2.5rem;

            border-radius: 0.3rem;

            .title {
                font-size: 1rem;
            }

            .description {
                font-size: 1rem;
            }
        }
    }

    @media screen and (max-width: 768px) {
        height: 100%;

        display: flex;
        justify-content: center;

        margin-top: 0;

        padding: 1rem;

        .section {
            border-radius: 0;
        }
    }
`;