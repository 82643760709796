import { Container } from "./styles";

import { useNavigate } from "react-router-dom";

import FolderOpen from "@mui/icons-material/FolderOpen";

import { toast } from "react-toastify"
import { api } from "../../services/axios";
import { useAuth } from "../../hooks/useAuth";

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

export function ImportUsersCard() {
    const { setLoading } = useAuth();
    const file = new FormData();

    const navigate = useNavigate();

    function handleSubmit(e: any) {
        if(!e.target.files) {
            toast.error("Você precisa selecionar um arquivo!");
        };

        file.append("file", e.target.files[0]);
        uploadFile();
    }

    async function uploadFile() {
        setLoading(true);

        const data = async () => await api.post('/users/import', file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        data()
            .then(() => {
                toast.success('Lista de usuários atualizada com sucesso!');
                navigate("/");
            })
            .catch((err) => {
                toast.error(err.response.data.message);
                navigate("/");
            });
        
        setLoading(false);
    }

    return (
        <Container>
            <div className="import_card">
                <p className="description">Selecione um arquivo <strong>csv</strong> em sua maquina para importar usuários</p>
                <img src="/assets/images/upload.svg" className="students_image" alt="students"/>

                <label className="button import">
                    <FolderOpen/>
                    Importar usuários

                    <input id="input" onChange={handleSubmit} type="file" accept=".csv"/>
                </label>

                <a href="/assets/others/template.xlsx" className="button">
                    <InsertDriveFileIcon/>
                    Template excel
                </a>
            </div>
        </Container>
    )
};