import { FormEvent, useEffect, useState } from "react";
import { Container } from "./styles";

import { Link, useNavigate } from "react-router-dom";

import FolderOpen from "@mui/icons-material/FolderOpen";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";

import { useAuth } from "../../hooks/useAuth";

import { api } from "../../services/axios";
import { toast } from "react-toastify";

import Autocomplete from '@mui/material/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

interface IModule {
    id: string;
    name: string;
}

interface IPeriod {
    id: string;
    name: string;
}

interface IFileData {
    file_key: string;
}

interface IBackgroundData {
    background_key: string;
}

export function UploadPdfCard() {
    const [materialName, setMaterialName] = useState('');
    const [materialDescription, setDescription] = useState('');

    const [periods, setPeriods] = useState<IPeriod[]>([])
    const [periodId, setPeriodId] = useState('');
    
    const [modules, setModules] = useState<IModule[]>([]);
    const [moduleId, setModuleId] = useState<String | undefined>();

    const [uploaded, setUpload] = useState(false);
    const [fileData, setFileData] = useState<IFileData>();

    const [incorrectText, setIncorrectText] = useState(false);

    const [backgroundKey, setBackgroundKey] = useState<IBackgroundData | null>(null);

    const file = new FormData();

    const { setLoading } = useAuth();

    const navigate = useNavigate();

    useEffect(() => {
        const modulesData = async () => await api.get('/modules/listall')

        modulesData()
            .then((response) => {
                const modules = response.data;
                
                setModules(modules);
            })
            .catch(() => {
                toast.error('Algo deu errado');
            })

        const periodsData = async () => await api.get('/periods')

        periodsData()
            .then((response) => {
                const periods = response.data;
                
                setPeriods(periods);
            })
            .catch(() => {
                toast.error('Algo deu errado');
            })
    }, [])

    useEffect(() => {
        const file = localStorage.getItem("fileData");
        const materialName = localStorage.getItem("material_name");
        const materialDescription = localStorage.getItem("material_description");
        const periodId = localStorage.getItem("material_periodId");

        if(file) {
            toast.warn("Progresso restaurado");
            setFileData({ file_key: file })
            setUpload(true);
        };

        const background = localStorage.getItem("backgroundKey");

        if(background) {
            setBackgroundKey({ background_key: background });
            setUpload(true);
        }

        if(materialName) {
            setMaterialName(materialName);
        }

        if(materialDescription) {
            setDescription(materialDescription);
        }

        if(periodId) {
            setPeriodId(periodId);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem("material_name", materialName);
        localStorage.setItem("material_description", materialDescription);
        localStorage.setItem("material_periodId", periodId);

        if(materialDescription) {
            if(materialDescription.length < 30) {
                return setIncorrectText(true);
            } else {
                setIncorrectText(false);
            }
        }
    }, [
        incorrectText,
        materialDescription,
        materialName,
        periodId,
        moduleId,
    ]);

    function clearLocalStorage() {
        localStorage.removeItem("fileData");
        localStorage.removeItem("backgroundKey")
        localStorage.removeItem("material_name");
        localStorage.removeItem("material_description");
        localStorage.removeItem("material_periodId");
    }

    async function handleUploadPdfFile(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        if(!e.target.files) {
            toast.error("Você precisa selecionar um arquivo!");
        };

        file.append("file", e.target.files![0]);

        uploadPdfFile();
    }

    async function uploadPdfFile() {
        setLoading(true);

        const data = async () => await api.post<IFileData>('/materials/upload/file', file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        toast.promise(data, {
            pending: 'Carregando..',
            success: 'Material processado com sucesso',
            error: 'Algo deu errado',
        });

        data()
            .then((response) => {
                const data = response.data;

                setFileData(data);

                setLoading(false);

                localStorage.setItem("fileData", data.file_key);

                file.delete("file");

                return setUpload(!uploaded);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
                setLoading(false);

                file.delete("file");
                
                return navigate("/upload/material");
            })
    }

    async function handleUploadImage(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        
        if(!e.target.files) {
            toast.error('Você precisa selecionar alguma imagem');
        };

        file.append("image", e.target.files![0]);
    
        await uploadImage();
    }

    async function uploadImage() {
        const data = async () => await api.post<IBackgroundData>('/materials/upload/background', file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        toast.promise(data, {
            pending: 'Carregando..',
            success: 'Plano de fundo processado com sucesso',
            error: 'Algo deu errado',
        });

        data()
            .then((response) => {
                const data = response.data;

                setBackgroundKey(data);
                localStorage.setItem("backgroundKey", data.background_key);
                file.delete("image");
            })
            .catch((err) => {
                toast.error(err.response.data.message);
                file.delete("image");
                
                return navigate("/upload/material");
            })
    }

    async function handleSubmit(event: FormEvent) {
        event.preventDefault()

        if (
            !materialName || !materialDescription || !periodId
        ) {
            return toast("Você precisa preencher todos os campos!");
        };

        if(materialDescription.length < 30) {
            return toast('Você precisa escrever no mínimo 30 caracteres na descrição');
        };

        if(materialDescription.length > 180) {
            return toast('Você não pode escrever mais que 180 caracteres na descrição');
        };

        if(!backgroundKey) {
            return toast('Você precisa adicionar um plano de fundo para o material');
        };

        const data = async () => api.post("/materials", {
            name: materialName,
            description: materialDescription,
            period_id: periodId,
            module_id: moduleId,
            file_key: fileData?.file_key,
            background_key: backgroundKey.background_key
        })

        data()
            .then(() => {
                toast.success('Material adicionado com sucesso!');
                clearLocalStorage();
                return navigate('/material');
            })
            .catch((err) => {
                toast.error(err.response.data.message);
                return navigate('/upload/material')
            });
    }

    return (
        <Container>
            {
                !uploaded ? (
                    <div className="upload_card">
                        <span>Escolha um arquivo pdf para publicar</span>

                        <img src="/assets/images/upload.svg" alt="students" />

                        <label className="button import">
                            <input onChange={handleUploadPdfFile} id="inputPdf" type="file" accept=".pdf" />
                            
                            <FolderOpen />
                            Enviar
                        </label>


                        <Link className="button" style={{ textDecoration: 'none' }} to={'/'}>
                            Voltar
                        </Link>
                    </div>
                ) : (
                    <div className="main">
                        <h2 className="title">Informações</h2>
                        <p className="description">Preencha os dados abaixo para prosseguir</p>

                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '1' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                id="outlined-name"
                                label="Nome"
                                value={materialName}
                                onChange={event => setMaterialName(event.target.value)}
                                required
                            />

                            <TextField
                                id="outlined-multiline-static"
                                label="Descrição"
                                multiline
                                value={materialDescription}
                                onChange={event => setDescription(event.target.value)}
                                rows={5}
                                helperText={incorrectText && 'Você precisa escrever no mínimo 30 caracteres'}
                                error={incorrectText && true}
                                required
                            />

                            <TextField
                                id="outlined-name"
                                label="ID do material"
                                value={fileData?.file_key}
                                disabled
                                required
                            />

                            <Autocomplete
                                id="highlights-demo"
                                sx={{ width: 300 }}
                                options={periods}
                                getOptionLabel={(periods) => periods.id}
                                onChange={(event, periods) => {
                                    if(periods) {
                                        setPeriodId(periods?.id);
                                    }
                                }}
                                renderInput={(params) => (
                                    <>
                                        <TextField {...params} label="Período" helperText="Este material só podera ser acessado pelos alunos cadastrados no período selecionado." margin="normal" required/>
                                    </>
                                )}
                                renderOption={(props, periods, { inputValue }) => {
                                    const matches = match(periods.id, inputValue);
                                    const parts = parse(periods.id, matches);

                                    return (
                                        <li {...props}>
                                            <div>
                                                {parts.map((part, index) => (
                                                    <span
                                                        key={index}
                                                        style={{
                                                            fontWeight: part.highlight ? 700 : 400,
                                                        }}
                                                    >
                                                        {part.text}
                                                    </span>
                                                ))}
                                            </div>
                                        </li>
                                    );
                                }}
                            />

                            <Autocomplete
                                id="highlights-demo"
                                sx={{ width: 300 }}
                                options={modules}
                                getOptionLabel={(modules) => modules.id}
                                onChange={(event, modules) => {
                                    if(modules) {
                                        setModuleId(modules?.id);
                                    }
                                }}
                                renderInput={(params) => (
                                    <>
                                        <TextField {...params} label="Módulo" helperText="Ao selecionar um módulo, vocẽ vai estar anexando o material ao mesmo." margin="normal" />
                                    </>
                                )}
                                renderOption={(props, modules, { inputValue }) => {
                                    const matches = match(modules.id, inputValue);
                                    const parts = parse(modules.id, matches);

                                    return (
                                        <li {...props}>
                                            <div>
                                                {parts.map((part, index) => (
                                                    <span
                                                        key={index}
                                                        style={{
                                                            fontWeight: part.highlight ? 700 : 400,
                                                        }}
                                                    >
                                                        {part.text}
                                                    </span>
                                                ))}
                                            </div>
                                        </li>
                                    );
                                }}
                            />

                            {
                                !backgroundKey ? (
                                    <>        
                                        <label className="image_import">
                                            <FolderOpen />
                                            Selecionar plano de fundo

                                            <input onChange={handleUploadImage} id="inputImage" type="file" accept="image/*" />
                                        </label>
                                    </>
                                ) : (
                                    <>
                                        <TextField
                                            id="outlined-name"
                                            label="ID do plano de fundo"
                                            value={backgroundKey.background_key}
                                            disabled
                                            required
                                        />
                                    </>
                                )
                            }

                            <Button onClick={handleSubmit} variant="contained" style={{
                                height: '3.5rem',
                                background: '#2196f3',
                                textTransform: 'none',
                                fontSize: '1rem' 
                            }} size="medium">
                                Salvar
                            </Button>
                        </Box>
                    </div>
                )
            }
        </Container>
    )
};