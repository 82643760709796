import styled from "styled-components";

export const Container = styled.div`
    min-height: 100vh;
    width: 100%;

    background-image: linear-gradient(rgb(14 13 13 / 30%),rgb(0 0 0 / 32%)),url(/assets/images/students.svg);
    object-fit: cover;
    background-size: cover;

    padding-top: 4.5rem;

    @media screen and (max-width: 768px) {
        height: 100%;
    }
`;

export const Main = styled.div`
    .wrapper {
        height: 100%;
        max-width: 1440px;

        display: flex;
        justify-content: center;

        margin: 0 auto;

        margin-top: 1.5rem;

        .content {
            max-width: 28rem;
            height: 33rem;

            display: flex;
            flex-direction: column;
            justify-content: center;

            align-items: center;

            background-color: var(--white-color);

            align-items: center;

            border: 0.0625rem solid #dadce0;
            border-radius: 0.8rem;

            padding: 3rem 3rem;
    
            gap: 1rem;

            .logo {
                display: flex;
                justify-content: space-between;

                margin-bottom: 0.5rem;

                max-width: 13rem;
                object-fit: cover;
            }

            .title {
                font-family: 'Lexend', sans-serif;
                font-size: 1rem;
                font-weight: 300;
                color: var(--text-color);
            }
    
            .description {
                font-family: 'Lexend', sans-serif;
                font-size: 1rem;
                font-weight: 300;
                line-height: 1.5rem;
                color: var(--text-color);
                max-width: 30rem;
            }

            .buttons {
                width: 100%;
                display: flex;
                flex-direction: column;

                gap: 0.5rem;
            }
    
            .button {
                width: 100%;
                height: 3.3rem;

                display: flex;
                justify-content: center;

                align-items: center;

                padding: 0.8rem 3rem;

                gap: 0.3rem;

                border-style: none;
                border-radius: 0.2rem;

                background: var(--button-color);
                color: var(--white-color);
                
                font-family: 'Lexend', sans-serif;
                font-weight: 500;
                font-size: 1rem;

                text-decoration: none;

                cursor: pointer;

                &:hover {
                    filter: brightness(0.9);
                }
            }

            .download {
                background: var(--blue-color);

                &:hover {
                    filter: opacity(0.9);
                }
            }
        }

        @media screen and (max-width: 768px) {
            .content {
                max-width: 22rem;
                height: 35rem;

                display: flex;
                justify-content: center;

                align-items: center;

                padding: 4rem 2rem;

                .button {
                    width: 100%;
                    display: flex;

                    align-items: center;

                    text-align: center;
                }
            }
        }
    }
`;