import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Container, Wrapper, Main } from "./styles";
import { Header } from "../../components/Header"

import Box from '@mui/material/Box';
import Button from "@mui/material/Button";

import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { NProgressFunction } from "../../hooks/useNprogress";
import { toast } from "react-toastify";

import { api } from "../../services/axios";
import { useAuth } from "../../hooks/useAuth";

export function ChangeUserPassword() {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const [showPassword, setShowPassword] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showConfirmCurrentPassword, setShowConfirmCurrentPassword] = useState(false);

    const [incorrectNewPass, setIncorrectPass] = useState(false);
    const [incorrectConfirmNewPass, setIncorrectConfirmNewPass] = useState(false);

    useEffect(() => {
        if(newPassword) {
            if(newPassword.length < 6) {
                return setIncorrectPass(true);
            } else {
                setIncorrectPass(false);
            }
    
            if(confirmNewPassword !== newPassword) {
                return setIncorrectConfirmNewPass(true);
            } else {
                setIncorrectConfirmNewPass(false);
            };
        }
    }, [confirmNewPassword, newPassword, currentPassword]);

    const { userRole, signOut } = useAuth();

    const navigate = useNavigate();

    async function handleClick() {
        if(!newPassword) {
            return toast('Você precisa preencher todos os campos');
        }

        if(!currentPassword) {
            return toast('Você precisa preencher todos os campos');
        }

        if(confirmNewPassword !== newPassword) {
            return toast.error("As senhas não coincidem")
        }

        await api.post('/users/change_password', {
            current_password: currentPassword,
            new_password: newPassword
        })

        .then(() => {
            toast.success('Senha alterada com sucesso, efetue o login com as novas credenciais.');
            navigate('/');
            return signOut();
        })
        .catch((err) => {
            return toast.error(err.response.data.message);
        });
    }

    return (
        <Container>
            <Header/>

            <NProgressFunction/>

            <Main>
                <Wrapper>
                    <div className="form">
                        <h1 className="title">Alterar minha senha</h1>
                        <p className="description">Preencha os campos abaixos para definir uma nova credencial de acesso</p>

                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { marginTop: 1.5, width: '1' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <FormControl sx={{ width: 'full' }} variant="outlined" onSubmit={handleClick}>
                                <InputLabel htmlFor="standard-adornment-password">Senha atual</InputLabel>

                                <OutlinedInput
                                    id="outlined-current-pass"
                                    type={showPassword ? 'text' : 'password'}
                                    value={currentPassword ?? ''}
                                    onChange={event => setCurrentPassword(event.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={event => setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    autoComplete="off"
                                    label="new pass"
                                    required
                                />
                            </FormControl>

                            <FormControl>
                                <InputLabel htmlFor="standard-adornment-password">Nova senha</InputLabel>

                                <OutlinedInput
                                    id="outlined-new-pass"
                                    type={showCurrentPassword ? 'text' : 'password'}
                                    value={newPassword ?? ''}
                                    onChange={event => setNewPassword(event.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={event => setShowCurrentPassword(!showCurrentPassword)}
                                                edge="end"
                                            >
                                                {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    autoComplete="off"
                                    label="new pass"
                                    error={incorrectNewPass && true}
                                    required
                                />

                                {
                                    incorrectNewPass && (
                                        <>
                                            <p className="alert">A nova senha deve ter no mínimo 6 caracteres</p>
                                        </>
                                    )
                                }
                            </FormControl>

                            <FormControl>
                                <InputLabel htmlFor="standard-adornment-password">Confirmar senha</InputLabel>

                                <OutlinedInput
                                    id="outlined-confirm-pass"
                                    type={showConfirmCurrentPassword ? 'text' : 'password'}
                                    value={confirmNewPassword ?? ''}
                                    onChange={event => setConfirmNewPassword(event.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={event => setShowConfirmCurrentPassword(!showConfirmCurrentPassword)}
                                                edge="end"
                                            >
                                                {showConfirmCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    autoComplete="off"
                                    label="confirm-n-pass"
                                    error={incorrectConfirmNewPass && true}
                                    required
                                />
                            </FormControl>

                            <Button className="button save" onClick={handleClick} variant="contained" style={{ background: '#20B2AA', height: '3.5rem' }} size="medium">
                                Salvar
                            </Button>

                            {
                                userRole !== 'first_access' && (
                                    <>
                                        <Link to="/perfil" className="button">
                                            Voltar
                                        </Link>
                                    </>
                                )
                            }
                        </Box>
                    </div>
                </Wrapper>
            </Main>
        </Container>
    )
};