import { Container } from "./styles";

import { useAuth } from "../../hooks/useAuth";

import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';

import { Link } from "react-router-dom";

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AssignmentIcon from '@mui/icons-material/Assignment';

export function Sidebar() {
    const { user } = useAuth();

    return (
        <Container>
            <div className="content_profile">
                <h2 className="title">Meu Perfil</h2>

                <div className= "avatar">
                    <Avatar style={{ cursor: 'pointer', width: 100, height: 100, fontSize: '1.5rem' }}sx={{ bgcolor: deepOrange[500] }}>
                        { !user?.avatar_key ? `${user?.name[0]}` : <img src={`${process.env.REACT_APP_CDN}/avatar/${user?.avatar_key}`} alt="avatar" /> }
                    </Avatar>
                </div>

                <Link style={{ textDecoration: 'none' }} to={'/perfil'}>
                    <button className="button">
                        <ManageAccountsIcon/>
                        Meu perfil
                    </button>
                </Link>

                <Link style={{ textDecoration: 'none' }} to={'/users'}>
                    <button className="button">
                        <AssignmentIcon />
                        Usuários
                    </button>
                </Link>
            </div>
        </Container>
    )
};