import styled from "styled-components";

export const Container = styled.div`
    min-height: 100vh;

    display: flex;
    flex-direction: column;

    background-image: linear-gradient(rgb(14 13 13 / 30%),rgb(0 0 0 / 32%)),url(/assets/images/student.svg);
    object-fit: cover;
    background-size: cover;

    padding-top: 4.5rem;

    @media screen and (max-width: 768px) {
        height: 100%;
    }
`;

export const Main = styled.div`
    max-width: 1440px;
    width: 100%;

    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 1.2rem 2.5rem;

    .welcome_message {
        display: flex;
        flex-direction: column;

        align-items: center;

        gap: 0.6rem;

        padding: 1rem 2rem;

        .title {
            font-family: 'Lexend', sans-serif;
            font-size: 1.5rem;
            font-weight: 400;
            color: var(--white-color);
        }

        .description {
            font-family: 'Lexend', sans-serif;
            font-size: 1rem;

            font-weight: 300;
            text-align: start;
            
            line-height: 1.4rem;

            color: var(--gray-color);
        }

        @media screen and (max-width: 768px) {
            display: flex;
            justify-content: center;

            padding: 1rem;

            .description {
                width: 20rem;
                text-align: center;
            }
        }
    }

    .main {
        display: flex;
        align-items: center;
    }

    @media screen and (max-width: 968px) {
        display: flex;
        justify-content: center;

        align-items: center;

        .sidebar {
            display: none;
        }
    }
`;

export const Grid = styled.main`
    display: grid;
    grid-template-columns: 1fr 3fr;

    gap: 1rem;

    padding: 1rem 2rem;

    @media screen and (max-width: 968px) {
        display: flex;
        justify-content: center;

        flex-wrap: wrap;

        padding: 1.5rem;

        .table {
            border-radius: 1rem;
        }
    }
`;