import styled from "styled-components";

export const Container = styled.div`
    display: flex;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    background: var(--gray-color);

    border-top: 4px solid var(--blue-color);

    gap: 1.4rem;
    
    border-radius: 0.4rem;

    max-width: 15rem;
    height: 20rem;
    
    padding: 1rem 3rem;

    transition: 0.3s ease;
    
    .title {
        font-family: 'Lexend', sans-serif;
        font-size: 1rem;
        color: var(--text-color);
        font-weight: 300;
        line-height: 1.2rem;
        text-overflow: ellipsis;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    }
    
    .content {
        display: flex;
        justify-content: center;

        .note_image {
            max-width: 10rem;
        }
        
        .buttons {
            display: flex;
            flex-direction: column;
            justify-content: center;

            gap: 0.4rem;

            button {
                width: 9rem;
            }
        }
    }
    
    &:hover {
        transform: scale(1.01);
        transition: 0.4s ease;
    }

    .clicked {
        transform: scale(1.01);
    }
    
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;

        padding: 2rem 3rem;

        max-width: 15rem;
        height: 22rem;
    }
`;