import { Container } from "./styles";
import { useAuth } from "../../hooks/useAuth";

import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { api } from "../../services/axios";

interface IMaterialInfo {
    total_materials: number;
    week_materials: number;
}

export function ProfileCard() {
    const [materialInfo, setMaterialInfo] = useState<IMaterialInfo>();

    const { user, setLoading } = useAuth();

    useEffect(() => {
        setLoading(true);
        const materialInfo = async () => await api.get<IMaterialInfo>('/materials/info');

        materialInfo()
            .then((response) => {
                const materialInfo = response.data;

                setMaterialInfo(materialInfo);
            })
        
        setLoading(false);
    }, [setLoading]);

    return (
        <Container>
            <div className="content_profile">
                <h2 className="title">Meu Perfil</h2>

                <div className= "wrapper">
                    <Avatar style={{ cursor: 'pointer', width: 128, height: 128, fontSize: '2.5rem' }}sx={{ bgcolor: deepOrange[500] }}>
                        { !user?.avatar_key ? `${user?.name[0]}` : <img className="avatar_image" src={`${process.env.REACT_APP_CDN}/avatar/${user?.avatar_key}`} alt="avatar" /> }
                    </Avatar>
                </div>

                <Link style={{ textDecoration: 'none' }} to={'/perfil'}>
                    <button className="button">Visualizar perfil</button>
                </Link>
            </div>

            <div className="container">
                <div className="container_info">
                    <label>Novos materiais:</label>

                    <p>
                        {
                            !materialInfo?.week_materials ? (
                                <>
                                    Nenhum material adicionado esta semana
                                </>
                            ) : (
                                <>
                                    { 
                                        materialInfo.week_materials > 1 ? (
                                            <>
                                                { materialInfo.week_materials } materiais adicionados essa semana
                                            </>
                                        ) : (
                                            <>
                                                { materialInfo.week_materials } material adicionado essa semana
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                    </p>
                    
                    <label>Você está logado como:</label>
                    
                    <p>{ user?.email }</p>
                    
                    <label>Materiais disponíveis:</label>

                    <p>
                        {
                            !materialInfo?.total_materials ? (
                                <> 
                                    Nenhum material disponível
                                </>
                            ) : (
                                <>
                                    {
                                        materialInfo.total_materials > 1 ? (
                                            <>
                                                { materialInfo.total_materials } materiais disponíveis
                                            </>
                                        ) : (
                                            <>
                                                { materialInfo.total_materials } material disponível
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                    </p>
                </div>
            </div>
        </Container>
    );
};