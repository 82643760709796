import { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";

import { Container, Main, Wrapper } from "./styles";
import { Header } from "../../components/Header"

import Box from '@mui/material/Box';
import Button from "@mui/material/Button";

import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { NProgressFunction } from "../../hooks/useNprogress";
import { toast } from "react-toastify";

import { api } from "../../services/axios";

export function ResetForgotPassword() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [incorrectPassword, setIncorrectPassword] = useState(false);
    const [incorrectConfirmNewPass, setIncorrectConfirmNewPass] = useState(false);

    const { token } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        if(newPassword) {
            if(newPassword.length < 6) {
                return setIncorrectPassword(true);
            } else {
                setIncorrectPassword(false);
            }
    
            if(confirmPassword !== newPassword) {
                return setIncorrectConfirmNewPass(true);
            } else {
                setIncorrectConfirmNewPass(false);
            };
        }
    }, [confirmPassword, newPassword]);


    async function handleClick() {
        if(!token || !newPassword) {
            return toast('Você precisa preencher todos os campos')
        }

        if(newPassword !== confirmPassword) {
            return setIncorrectPassword(true);
        }

        await api.post('/users/forgot/new-password', {
            token,
            new_password: newPassword
        })

        .then(() => {
            toast.success('Senha alterada com sucesso!');
            return navigate('/');
        })
        .catch((err) => {
            return toast.error(err.response.data.message);
        });
    }

    return (
        <Container>
            <Header/>

            <NProgressFunction/>

            <Main>
                <Wrapper>
                    <div className="section">
                        <h1 className="title">Criar uma nova senha</h1>
                        <p className="description">Preencha os campos abaixos para definir uma nova credencial de acesso</p>

                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { marginTop: 1.5, width: '1' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <FormControl>
                                <InputLabel htmlFor="standard-adornment-password">Nova senha</InputLabel>

                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={newPassword ?? ''}
                                    onChange={event => setNewPassword(event.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={event => setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    autoComplete="off"
                                    label="new pass"
                                    error={incorrectPassword && true}
                                    required
                                />
                            
                                {
                                    incorrectPassword && (
                                        <>
                                            <p className="alert">A nova senha deve ter no mínimo 6 caracteres</p>
                                        </>
                                    )
                                }
                            </FormControl>

                            <FormControl>
                                <InputLabel htmlFor="standard-adornment-password">Confirme a senha</InputLabel>

                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    value={confirmPassword ?? ''}
                                    onChange={event => setConfirmPassword(event.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={event => setShowConfirmPassword(!showConfirmPassword)}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    autoComplete="off"
                                    label="confirm password"
                                    error={incorrectConfirmNewPass && true}
                                    required
                                />
                            </FormControl>

                            <Button className="button save" onClick={handleClick} variant="contained" style={{ background: '#20B2AA', height: '3.5rem' }} size="medium">
                                Salvar
                            </Button>

                            <Link to="/perfil" className="button">
                                Voltar
                            </Link>
                        </Box>
                    </div>
                </Wrapper>
            </Main>
        </Container>
    )
};