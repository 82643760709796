import { FormEvent, useEffect, useState } from "react";

import validator from "validator";

import { Header } from "../../components/Header";
import { Container, Main, Form } from "./styles";

import "../../assets/nprogress/nprogress.css";
import { NProgressFunction } from "../../hooks/useNprogress";
import { Button, TextField } from "@mui/material";

import { api } from "../../services/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export function ForgotPassword() {
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [email, setEmail] = useState('');
    
    const navigate = useNavigate();

    function validateEmail(email: string) {
        if(validator.isEmail(email)) {
            setEmail(email);
            return setIsValidEmail(true);
        }

        return setIsValidEmail(false);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    async function handleSubmit(event: FormEvent) {
        event.preventDefault();

        await api.post('/users/forgot', {
            email
        })
        
        .then(() => {
            navigate('/')
            toast.success('Estamos quase lá, verifique sua caixa de entrada :)')
        })
        .catch((err) => {
            toast.error(err.response.data.message);
        })
    }

    return (
        <Container>
            <NProgressFunction/>

            <Header/>

            <Main>
                <div className="wrapper">
                    <h2 className="title">Deseja alterar a sua senha?</h2>
                    <p className="description">Digite o e-mail cadastrado em sua conta e envie, após isso verifique sua caixa de entrada!</p>
               
                    <Form onSubmit={handleSubmit}>
                        <TextField
                            id="outlined-name"
                            label="E-mail"
                            fullWidth
                            type={"email"}
                            inputProps={{ inputMode: 'email' }}
                            onChange={(event) => validateEmail(event.target.value)}
                            required
                        />
                        
                        {
                            isValidEmail ? <>
                                <Button onClick={handleSubmit} variant="contained" className="button" size="medium">
                                    Enviar
                                </Button>
                            </> :
                            <>
                                <Button variant="contained" className="button" size="medium" disabled>
                                    Enviar
                                </Button>
                            </>
                        }
                    </Form>
               </div>
            </Main>
        </Container>
    )
}