/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from "react";
import { ContentCard } from "../../components/ContentCard";
import { Header } from "../../components/Header";
import { Container, Main, Section } from "./styles";

import { toast } from "react-toastify";
import { NProgressFunction } from "../../hooks/useNprogress";

import { api } from "../../services/axios";

import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

interface IMaterial {
    id: string;
    name: string;
    description: string;
    file_key: string;
    background_key: string;
}

export function MainContent() {
    const [materials, setMaterials] = useState<IMaterial[]>();
    const [lastAccessedMaterial, setLastMaterial] = useState<IMaterial>();
 
    const { user, setLoading } = useAuth();

    const navigator = useNavigate();

    useEffect(() => {
        setLoading(true);

        const materialData = async () => await api.get<IMaterial[]>("/materials");

        materialData()
            .then((response) => {
                const materialsData = response.data;

                setMaterials(materialsData);

                if(materialsData.length === 0) {
                    toast.warn("Não temos materiais disponíveis para seu período, caso isso persista, entre em contato com a equipe Genminando.")
                    return navigator("/");
                }
            })
            .catch(() => {
                toast.error("Algo deu errado!");
            });

        const lastMaterialId = localStorage.getItem('last_accessed_material');

        if(lastMaterialId) {
            const lastAccessedMaterial = async () => await api.get(`/materials/${lastMaterialId}`);
    
            lastAccessedMaterial() 
                .then((response) => {
                    const materialData = response.data;
    
                    setLastMaterial(materialData);
                });
        }

        setLoading(false);
    }, []);

    return (
        <Container>
            <Header />

            <NProgressFunction />

            <Main>
                <Section>
                    <div className="contents">
                        <div className="content info">
                            <h2 className="info_title"> Matrícula: {user?.period?.name} </h2>
                            <p className="info_id"> ID: {user?.period?.id}</p>
                        </div>
                        
                        {
                            lastAccessedMaterial && (
                                <div className="last_accessed_contents">
                                    <h2 className="content_title">Continue estudando</h2>
                                    <div className="contents_cards">
                                        <ContentCard
                                            id={lastAccessedMaterial.id}
                                            key={'lastAccessedMaterial'}
                                            name={lastAccessedMaterial.name}
                                            description={lastAccessedMaterial.description}
                                            file_key={lastAccessedMaterial.file_key}
                                            background_key={lastAccessedMaterial.background_key}
                                        />
                                    </div>
                                </div>

                            )
                        }
                    <div className="new_contents">
                        <h2 className="content_title">Materiais disponíveis</h2>

                        <div className="contents_cards">
                            {
                                materials?.map((material) => (
                                    <ContentCard
                                        id={material.id}
                                        key={material.id}
                                        name={material.name}
                                        description={material.description}
                                        file_key={material.file_key}
                                        background_key={material.background_key}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div>
                </Section>
            </Main>
        </Container>
    )
}