import styled, { keyframes } from "styled-components";

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-image: linear-gradient(rgb(14 13 13 / 30%),rgb(0 0 0 / 32%)),url(/assets/images/banner.jpg);
    object-fit: cover;
    background-size: cover;

    height: 100vh;
`;

export const Wrapper = styled.main`
    max-width: 1440px;
    margin: 0 auto;

    display: flex;
    justify-content: center;

    transition: 0.4s ease;

    @media screen and (max-width: 1180px) {
        display: flex;
        justify-content: center;
    }

    @media screen and (max-width: 968px) {
        display: flex;
        justify-content: center;
    }
`;

const showContent = keyframes`
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const Form = styled.form`
    display: flex;
    justify-content: center;
    flex-direction: column;
    
    background: var(--white-color);
    
    padding: 1rem 3rem 3rem;

    width: 26rem;
    height: 37rem;

    border-radius: 0.2rem;
    box-shadow: 10px 3px 20px rgb(58 58 1 / 10%);
    
    opacity: 0;
    animation: ${showContent} 0.3s forwards ease;
    transform: translateX(-8rem);

    .logo {
        display: flex;
        justify-content: space-between;

        margin-bottom: 0.5rem;

        max-width: 5rem;
        object-fit: cover;
        clip-path: circle();
    }

    .form__title {
        font-family: 'Lexend', sans-serif;
        font-size: 2rem;
        
        text-align: left;
        line-height: 2.5rem;

        color: var(--blue-color);
        font-weight: 400;
    }

    label {
        display: flex;
        flex-direction: column;

        gap: 1.3rem;
        
        span {
            font-size: 1rem;
            font-family: 'Lexend', sans-serif;
            font-weight: 300;
            color: var(--text-color);

            margin-top: 1rem;
        }
    }

    .button {
        display: flex;
        justify-content: center;
        
        padding: 1rem;
        margin-top: 1rem;

        border-style: none;
        border-radius: 0.2rem;

        background: var(--blue-color);
        color: var(--white-color);
        
        font-family: 'Lexend', sans-serif;
        font-weight: 500;
        font-size: 1rem;

        cursor: pointer;

        &:hover {
            filter: brightness(0.9);
        }
    }

    .back {
        background-color: var(--button-color);
    }

    .forgot_password {
        font-family: 'Lexend', sans-serif;
        font-size: 1rem;
        color: #179c95e0;

        font-weight: 400;

        text-decoration: none;
        text-align: center;

        line-height: 3.3rem;

        &:hover {
            filter: brightness(0.7);
        }
    }

    @media screen and (max-width: 768px){
        display: flex;

        max-width: 100%;
        border-radius: 0;

        height: 100vh;
        width: 100vw;

        background: #fffffff5;

        transition: 0.2s ease;
    }
`;