import { useEffect, useState } from "react";
import { Container, Wrapper } from "./styles"

import { Header } from "../../../components/Header"

import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import { toast } from "react-toastify";

import FolderOpen from "@mui/icons-material/FolderOpen";

import { useNavigate, Link } from "react-router-dom";

import { api } from "../../../services/axios";
import { NProgressFunction } from "../../../hooks/useNprogress";

import FormControl from '@mui/material/FormControl';

import Autocomplete from '@mui/material/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

interface IPeriod {
    id: string;
    name: string;
}

export function CreateModule() {
    const [moduleName, setModuleName] = useState('');
    const [moduleDescription, setModuleDescription] = useState('');
    const [backgroundKey, setBackgroundKey] = useState('');
    
    const [periodId, setPeriodId] = useState<String | undefined>('');
    const [periods, setPeriods] = useState<IPeriod[]>([]);

    const file = new FormData();

    const navigate = useNavigate();

    useEffect(() => {
        const data = async () => await api.get('/periods')

        data()
            .then((response) => {
                const periods = response.data;
                
                setPeriods(periods);
            })
            .catch(() => {
                toast.error('Algo deu errado');
            })

    }, [])

    async function handleSelectImage(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        if(!e.target.files) {
            toast.error("Você precisa selecionar um arquivo!");
        }

        file.append("image", e.target.files![0]);

        handleUploadImage();
    }

    async function handleUploadImage() {
        const data = async () => await api.post('/modules/upload/background', file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        toast.promise(data, {
            pending: 'Carregando..',
            success: 'Plano de fundo processado com sucesso',
            error: 'Algo deu errado',
        });

        data()
            .then((response) => {
                const data = response.data;

                setBackgroundKey(data.background_key);
                file.delete("image");
            })
            .catch((err) => {
                toast.error(err.response.data.message);
                file.delete("image");
                
                return navigate("/modulos/adicionar");
            })
    }

    async function handleSubmit() {
        if(
            !moduleName &&
            !periodId &&
            !moduleDescription
        ) {
            return toast('Você precisa preencher todos os campos')
        }

        await api.post('/modules', {
            name: moduleName,
            description: moduleDescription,
            period_id: periodId,
            background_key: backgroundKey
        })

        .then(() => {
            toast.success('Módulo adicionado com sucesso!');
            return navigate('/modulos');
        })
        .catch((err) => {
            return toast.error(err.response.data.message);
        });
    }

    return (
        <Container>
            <Header/>

            <NProgressFunction/>

            <Wrapper>
                <div className="section">
                    <h2 className="title">Criar módulo</h2>
                    <p className="description">Preencha os campos abaixo para poder adicionar um novo módulo.</p>

                    <FormControl
                        component="form"
                        style={{ width: '100%' }}
                        sx={{
                            '& > :not(style)': { m: 1, width: '1' },
                        }}
                        noValidate
                        autoComplete="off"
                        >

                        <TextField
                            id="outlined-name"
                            label="Nome"
                            value={moduleName}
                            onChange={event => setModuleName(event.target.value)}
                            required
                        />

                        <TextField
                            id="outlined-name"
                            label="Descrição"
                            value={moduleDescription}
                            onChange={event => setModuleDescription(event.target.value)}
                            required
                        />

                        <Autocomplete
                            id="highlights-demo"
                            sx={{ width: 300 }}
                            options={periods}
                            getOptionLabel={(period) => period.id}
                            onChange={(event, period) => {
                                setPeriodId(period?.id);
                            }}
                            renderInput={(params) => (
                                <>
                                    <TextField {...params} label="Períodos" helperText="Selecione o período do qual esse módulo deve pertencer" margin="normal" required/>
                                </>
                            )}
                            renderOption={(props, periods, { inputValue }) => {
                                const matches = match(periods.id, inputValue);
                                const parts = parse(periods.id, matches);

                                return (
                                    <li {...props}>
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight: part.highlight ? 700 : 400,
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                        />

                        {
                            !backgroundKey ? (
                                <>        
                                    <label className="image_import">
                                        <FolderOpen />
                                        Selecionar plano de fundo

                                        <input onChange={handleSelectImage} id="inputImage" type="file" accept="image/*" />
                                    </label>
                                </>
                            ) : (
                                <>
                                    <TextField
                                        id="outlined-name"
                                        label="ID do plano de fundo"
                                        value={backgroundKey}
                                        disabled
                                        required
                                    />
                                </>
                            )
                        }

                        <Button onClick={handleSubmit} variant="contained" style={{ height: '3.5rem' }} size="medium">
                            Salvar
                        </Button>

                        <Link to={'/modulos'} className="button">
                            Voltar
                        </Link>
                    </FormControl>
                </div>
            </Wrapper>
        </Container>
    )
}