import styled from "styled-components";

export const Container = styled.div`
    max-width: 20rem;
    height: 28rem;
    
    display: flex;
    justify-content: center;
    
    align-items: center;

    background: var(--white-color);
    border-radius: 0.4rem;

    transition: 1s ease;

    padding: 1rem;

    gap: 2rem;

    border-top: 4px solid var(--blue-color);

    .import_card {
        display: flex;
        flex-direction: column;
        justify-content: center;

        align-items: center;

        gap: 1rem;

        .students_image {
            max-width: 12rem;
            padding: 1rem;
        }

        input {
            display: none;
        }

        .button {
            max-width: 12rem;
            width: 14rem;

            text-decoration: none;

            height: 3rem;

            display: flex;
            justify-content: center;

            align-items: center;

            text-align: center;

            border-style: none;
            border-radius: 0.2rem;

            gap: 0.4rem;

            background: var(--button-color);
            color: var(--white-color);
            
            font-family: 'Lexend', sans-serif;
            font-weight: 500;
            font-size: 1rem;

            cursor: pointer;

            &:hover {
                filter: brightness(0.9);
                background: var(--blue-color);
            }
        }

        .import {
            background-color: #5b52f7;

            &:hover {
                filter: brightness(0.9);
                background-color: #5b52f7;
            }
        }

        .description {
            font-family: 'Lexend', sans-serif;
            font-size: 1rem;
            color: var(--text-color);
            font-weight: 300;
            text-align: center;

            max-width: 15rem;
        }

        @media screen and (max-width: 1080px) {
            max-width: 23rem;
            width: 23rem;
            height: 20rem;

            display: flex;
            flex-direction: column;

            align-items: center;

            padding: 1rem 2rem;

            .button {
                padding: 0.9rem 0.7rem;
                gap: 0.2rem;

                font-size: 1rem;

                text-align: center;
            }
        }
    }
`;