import { Container } from "./styles";

import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { useAuth } from "../../hooks/useAuth";

import { Link } from "react-router-dom";

interface IProps {
    id: string;
    name: string;
    description: string;
    file_key: string;
    background_key: string;
}

export function ContentCard({ 
    id,
    name,
    description,
    file_key,
    background_key
}: IProps) {
    const { user } = useAuth();

    return (
        <Container background={ background_key ? `${process.env.REACT_APP_CDN}/images/${background_key}` : '/assets/images/student.svg' }>
            <div className="content_info">
                <span className="title">{ name }</span>
            
                <p className="description">
                    { description }
                </p>
            </div>

            <div className="buttons">
                <Link className="card_button" to={`/view/${id}`}>
                    Acessar <PictureAsPdfIcon style={{ fontSize: '1.3rem' }}/>
                </Link>
                
                {
                    String(user?.roles) === "teacher" && (
                        <>
                            <Link className="card_button download" to={`/material/download/${file_key}`} style={{ textDecoration: 'none' }}>
                                Baixar <DownloadIcon style={{ fontSize: '1.3rem' }}/>
                            </Link>
                        </>
                    )
                }
            </div>
        </Container>
    );
};