import styled from "styled-components";

export const Container = styled.div`
    height: 100vh;

    align-items: center;

    padding: 1rem;

    position: fixed;

    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    background: #000000b0;

    z-index: 12;
`;

export const Wrapper = styled.div`
    max-width: 1440px;

    display: flex;
    justify-content: center;

    margin: 0 auto;

    padding-top: 5rem;

    .card {
        max-width: 100%;
        width: 40rem;

        display: flex;
        flex-direction: column;

        background: var(--gray-color);

        border-radius: 0.3rem;

        padding: 3.5rem 3.5rem;

        gap: 1.5rem;

        .card_data {
            display: flex;
            flex-direction: column;

            gap: 1.3rem;

            .name {
                font-family: 'Roboto', sans-serif;
                font-size: 1.5rem;
                font-weight: 500;
                color: var(--text-color);
                line-height: 1.3rem;
                text-overflow: ellipsis;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
            }
    
            .description {
                font-family: 'Lexend', sans-serif;
                font-size: 1rem;
                font-weight: 300;
                color: var(--text-color);
            }
        }

        .divider {
            border-bottom: 1px solid #3c404345;
            margin: 0.5rem;
        }

        .card_info {
            display: flex;
            flex-direction: column;

            gap: 0.5rem;

            strong {
                font-family: 'Lexend', sans-serif;
                font-size: 1rem;
                font-weight: 300;
                color: var(--text-color);
            }
    
            .text {
                font-family: 'Lexend', sans-serif;
                font-size: 1rem;
                font-weight: 400;
                color: var(--text-color);
                text-overflow: ellipsis;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
            }
        }

        .button {
            width: 100%;
            height: 3rem;
    
            display: flex;
            justify-content: center;
    
            align-items: center;
    
            border-style: none;
            border-radius: 0.3rem;
    
            background: var(--blue-color);
            color: var(--white-color);
            
            font-family: 'Lexend', sans-serif;
            font-weight: 500;
            font-size: 1rem;
    
            text-decoration: none;
    
            cursor: pointer;
    
            &:hover {
                filter: brightness(0.9);
            }
        }
    }
`;