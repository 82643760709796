import styled from "styled-components";

export const Container = styled.div`
    min-height: 100vh;
    width: 100%;

    background: #1c2021;
    background-image: linear-gradient(rgb(14 13 13 / 30%),rgb(0 0 0 / 32%)),url(/assets/images/student.svg);

    padding-top: 4.5rem;

    @media screen and (max-width: 768px) {
        height: 100%;
    }
`;

export const Main = styled.div`
    width: 100%;
    height: 100%;

    display: flex;

    padding: 2rem 4rem;

    gap: 1.5rem;

    @media screen and (max-width: 768px) {
        height: 100%;

        display: flex;
        justify-content: center;
        flex-direction: column;

        padding: 1rem 2rem;
    }
`;

export const Aside = styled.aside<{ background: string }>`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    gap: 1rem;

    .aside__header {
        max-width: 100%;
        height: 15rem;

        display: flex;
        flex-direction: column;
        justify-content: center;

        background-image: linear-gradient(rgb(14 13 13 / 30%),rgb(0 0 0 / 32%)),url(${props => props.background});
        object-fit: cover;
        background-size: cover;

        border-radius: 0.4rem;

        padding: 2rem 4rem;

        .name {
            font-family: 'Roboto', sans-serif;
        
            font-size: 1.5rem;
            font-weight: 400;
            color: var(--white-color);

            text-overflow: ellipsis;
            word-wrap: break-word;
            overflow: hidden;
        }

        @media screen and (max-width: 968px) {
            max-width: 100%;

            display: flex;
            justify-content: center;

            padding: 2rem 3rem;

            .name {
                font-size: 1.5rem;
            }

            .description {
                font-size: 1.1rem;
            }
        }
    }

    .aside__container {
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        flex-direction: column;

        background: var(--gray-color);
        border-radius: 0.2rem;

        padding: 3rem 3rem;

        gap: 0.5rem;
        
        .title {
            font-family: 'Roboto', sans-serif;
            font-size: 1.5rem;
            font-weight: 300;

            color: var(--text-color);
        }

        strong {
            font-weight: 500;
        }

        .divider {
            margin: 0.3rem;
        }

        .description {
            font-family: 'Lexend', sans-serif;
            font-size: 1rem;
            font-weight: 300;
            line-height: 1.5rem;

            color: var(--text-color);
        }

        .date {
            font-family: 'Lexend', sans-serif;
            font-size: 1rem;
            font-weight: 300;
            line-height: 1.5rem;

            color: var(--text-color);
        }

        .progress {
            font-family: 'Roboto', sans-serif;
            font-size: 1rem;
            font-weight: 300;

            color: var(--text-color);
        }
    }
`;