import {
    createContext,
    ReactNode,
    useState
} from "react";

interface GlobalContextData {
    isAnnotation: boolean;
    isWriteAnnotation(): void;
    isSign(value: boolean): void;
    isSignInLoading: boolean;
    isChangeAvatar(): void;
    avatarModal: boolean;
    isShowModuleInfo(): void;
    showModuleInfo: boolean;
}

type GlobalContextProps = {
    children: ReactNode;
}

export const GlobalContext = createContext({} as GlobalContextData);

export function GlobalProvider({ children }: GlobalContextProps) {
    const [isAnnotation, setIsAnnotation] = useState(false);
    const [isSignInLoading, setIsSign] = useState(false);
    const [avatarModal, setAvatarModel] = useState(false);
    const [showModuleInfo, setShowModuleInfo] = useState(false);

    function isChangeAvatar() {
        return setAvatarModel(!avatarModal);
    }

    function isSign(value: boolean) {
        return setIsSign(value);
    }

    function isWriteAnnotation() {
        return setIsAnnotation(!isAnnotation);
    };

    function isShowModuleInfo() {
        return setShowModuleInfo(!showModuleInfo);
    }

    return (
        <GlobalContext.Provider value={{
            avatarModal,
            isChangeAvatar,
            isAnnotation,
            isWriteAnnotation,
            isSign,
            isSignInLoading,
            showModuleInfo,
            isShowModuleInfo
        }}>
            { children }
        </GlobalContext.Provider>
    )
}