/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { api } from "../../services/axios";

import { Header } from "../../components/Header"
import { User } from "../../interfaces/IUser";
import { Container, Main, Section} from "./styles"

import TextField, { TextFieldProps } from '@mui/material/TextField';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { alpha, styled } from '@mui/material/styles';

import { NProgressFunction } from "../../hooks/useNprogress";

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';

import { useAuth } from "../../hooks/useAuth";

import { Link, useNavigate } from "react-router-dom";
import { ProfileNavigation } from "../../components/ProfileNavigation";
import { UploadAvatarModel } from "../../components/UploadAvatarModal";
import { useGlobal } from "../../hooks/useGlobal";

const RedditTextField = styled((props: TextFieldProps) => (
    <TextField
        InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiFilledInput-root': {
        border: '1px solid #d1cfcf',
        overflow: 'hidden',
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'light' ? '#e2dfdf' : '#c7c7c7',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-focused': {
            backgroundColor: 'transparent',
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

export function Profile() {
    const [user, setUser] = useState<User | null>(null);

    const { signOut, setLoading } = useAuth();
    const { avatarModal } = useGlobal();

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setLoading(true);

        const userData = async () => await api.get<User>("/users/profile");

        userData()
            .then((response) => {
                const user: User = response.data;

                if(user.first_access) {
                    toast.warn('Identificamos que esse é seu primeiro acesso, sendo assim você precisa alterar a sua senha padrão!')
                }

                setUser(user);
                setLoading(false);
            })
            .catch(() => {
                toast.error("Algo deu errado!");
                setLoading(false);
                return navigate('/');
            })
    }, []);

    return (
        <Container>
            <Header />

            <NProgressFunction/>

            <Main>
                <ProfileNavigation/>

                <Section>
                    <div className="profile">
                        <h2 className="title">Meus Dados</h2>
                        <p className="description">Aqui abaixo estão todas as informações da sua conta</p>
                    </div>

                    {
                        user && (
                            <>
                                <FormControl
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { marginTop: 1.5, width: '1' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <RedditTextField
                                        label="Nome"
                                        disabled
                                        defaultValue={user?.name}
                                        id="reddit-input"
                                        variant="filled"
                                        style={{ marginTop: 11 }}
                                    />

                                    <RedditTextField
                                        label="E-mail"
                                        disabled
                                        defaultValue={user?.email}
                                        id="reddit-input"
                                        variant="filled"
                                        style={{ marginTop: 11 }}
                                    />

                                    <RedditTextField
                                        label="CPF"
                                        disabled
                                        defaultValue={user?.cpf}
                                        id="reddit-input"
                                        variant="filled"
                                        style={{ marginTop: 11 }}
                                    />

                                    <RedditTextField
                                        label="Matrícula"
                                        disabled
                                        defaultValue={user?.period?.name}
                                        id="reddit-input"
                                        variant="filled"
                                        style={{ marginTop: 11 }}
                                    />

                                    <RedditTextField
                                        label="Escola"
                                        disabled
                                        defaultValue={user?.school?.name}
                                        id="reddit-input"
                                        variant="filled"
                                        style={{ marginTop: 11 }}
                                    />

                                    <div className="buttons">
                                        <Link style={{ textDecoration: 'none' }} to={'/'}>
                                            <Button className="button" variant="contained" onClick={signOut} style={{ background: '#f72323' }}>
                                                Desconectar
                                            </Button>
                                        </Link>
                                    </div>
                                </FormControl>
                            </>
                        )
                    }
                </Section>
                
                {
                    avatarModal && <UploadAvatarModel/>
                }
            </Main>
        </Container>
    )
}