import { useState } from "react";
import { Container, Wrapper } from "./styles"

import { Header } from "../../../components/Header"

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";

import { useNavigate, Link } from "react-router-dom";

import { api } from "../../../services/axios";

import { toast } from "react-toastify";
import { NProgressFunction } from "../../../hooks/useNprogress";

export function CreateSchool() {
    const [schoolName, setSchoolName] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [studentsLimit, setStudentsLimit] = useState(0);

    const navigate = useNavigate();

    async function handleClick() {
        if(!schoolName) {
            return toast('Você precisa preencher todos os campos')
        }

        if(!cnpj) {
            return toast('Você precisa preencher todos os campos')
        }

        if(studentsLimit < 0) {
            return toast('O número de estudantes não pode ser negativo')
        }

        await api.post('/schools', {
            name: schoolName,
            cnpj: cnpj,
            students_limit: studentsLimit
        })

        .then(() => {
            toast.success('Escola adicionada com sucesso!');
            return navigate('/schools');
        })
        .catch((err) => {
            return toast.error(err.response.data.message);
        });
    }

    return (
        <Container>
            <Header/>

            <NProgressFunction/>

            <Wrapper>
                <div className="section">
                    <h2 className="title">Criar escola</h2>
                    <p className="description">Preencha os campos abaixo para poder adicionar uma nova escola.</p>

                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '1' },
                        }}
                        noValidate
                        autoComplete="off"
                        >
                        <TextField
                            id="outlined-name"
                            label="Nome"
                            value={schoolName}
                            onChange={event => setSchoolName(event.target.value)}
                            required
                        />
                        <TextField
                            id="outlined-uncontrolled"
                            label="CNPJ"
                            placeholder="00.000.000/0000-00"
                            onChange={event => setCnpj(event.target.value)}
                            required
                        />

                        <TextField
                            id="outlined-uncontrolled"
                            label="Limite de estudantes"
                            placeholder="0"
                            type={'number'}
                            inputProps={{
                                min: 0
                            }}
                            helperText="Não adicione um valor caso a escola não tenha um limite predefinido."
                            onChange={event => setStudentsLimit(Number(event.target.value))}
                        />

                        <Button onClick={handleClick} variant="contained" style={{ height: '3.5rem' }} size="medium">
                            Salvar
                        </Button>

                        <Link to={'/schools'} className="button">
                            Voltar
                        </Link>
                    </Box>
                </div>
            </Wrapper>
        </Container>
    )
}