import { BrowserRouter, Routes, Route } from "react-router-dom";

import { AdminDashboard } from "../pages/Admin/Dashboard";
import { ImportUsers } from "../pages/Admin/ImportUsers";
import { NotFound } from "../pages/NotFound";
import { Profile } from "../pages/Profile";
import { Periods } from "../pages/Admin/Periods"
import { Schools } from "../pages/Admin/Schools";
import { CreateSchool } from "../pages/Admin/CreateSchool";
import { CreatePeriod } from "../pages/Admin/CreatePeriod";
import { CreateUser } from "../pages/Admin/CreateUser";
import { ChangeUserPassword } from "../pages/ChangeUserPassword";
import { CreateMaterial } from "../pages/Admin/ImportMaterials";
import { Materials } from "../pages/Admin/Materials";
import { Modules } from "../pages/Admin/Modules";
import { CreateModule } from "../pages/Admin/CreateModule";
import { ViewPDF } from "../pages/ViewPdf";

export function Admin() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<AdminDashboard/>}/>
                <Route path="/perfil" element={<Profile/>}/>
                <Route path="/users" element={<ImportUsers/>}/>
                <Route path="/periods" element={<Periods/>}/>
                <Route path="/schools" element={<Schools/>}/>
                <Route path="/modulos" element={<Modules/>}/>
                <Route path="/modulos/adicionar" element={<CreateModule/>}/>
                <Route path="/schools/add" element={<CreateSchool/>}/>
                <Route path="/periods/add" element={<CreatePeriod/>}/>
                <Route path="/users/add" element={<CreateUser/>}/>
                <Route path="/change-password" element={<ChangeUserPassword/>}/>
                <Route path="/upload/material" element={<CreateMaterial/>}/>
                <Route path="/material" element={<Materials/>}/>
                <Route path="/view/:id" element={<ViewPDF/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </BrowserRouter>
    );
};