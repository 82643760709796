import styled from "styled-components";

export const Container = styled.div`
    min-height: 100vh;
    width: 100%;
    background: #1c2021;
    background-image: linear-gradient(rgb(14 13 13 / 30%),rgb(0 0 0 / 32%)),url(/assets/images/student.svg);
    padding-top: 4.5rem;
`;

export const Main = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
`;

export const Section = styled.section`
    max-width: 1440px;
    width: 100%;

    display: flex;
    
    margin: 1rem auto;

    padding: 3rem 4rem;

    .contents {
        width: 100%;

        display: flex;
        flex-direction: column;

        gap: 1rem;

        transition: 1s ease;

        .info {
            max-width: 100%;
            height: 15rem;

            display: flex;
            flex-direction: column;
            justify-content: center;

            background-image: linear-gradient(rgb(14 13 13 / 30%),rgb(0 0 0 / 32%)),url(/assets/images/banner.jpg);
            object-fit: cover;
            background-size: cover;

            border-radius: 0.4rem;

            padding: 2rem 4rem;

            .info_title {
                font-family: 'Roboto', sans-serif;
            
                font-size: 1.5rem;
                font-weight: 500;

                line-height: 2rem;
                color: var(--white-color);
            }

            .info_id {
                font-family: 'Lexend', sans-serif;
            
                font-size: 1.3rem;
                font-weight: 400;

                line-height: 2rem;

                color: var(--white-color);
            }

            @media screen and (max-width: 968px) {
                width: 100%;

                .info_title {
                    font-size: 1.3rem;
                }

                .info_id {
                    font-size: 1rem;
                }
            }
        }

        .last_accessed_contents, .new_contents {
            display: flex;
            justify-content: center;
            flex-direction: column;


            gap: 1rem;

            .content_title {
                font-family: 'Roboto', sans-serif;
                
                font-size: 1.5rem;
                font-weight: 500;
    
                line-height: 2rem;
    
                border-bottom: 3px solid var(--blue-color);
                width: 18rem;
                
                padding-bottom: 0.5rem;
                margin-bottom: 0.2rem;
                
                color: var(--white-color);
            }
        }


        .contents_cards {
            display: flex;
            flex-wrap: wrap;

            gap: 2rem;
        }
    }

    @media screen and (max-width: 1440px) {
        padding: 1rem 2rem;

        transition: 0.3s ease;
    }
    
    @media screen and (max-width: 968px) {
        display: flex;
        justify-content: center;

        .content {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }
`;