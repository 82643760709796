import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ChangeUserPassword } from "../pages/ChangeUserPassword";

export function FirstAccess() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<ChangeUserPassword/>}/>
            </Routes>
        </BrowserRouter>
    );
};