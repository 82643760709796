import styled from "styled-components";

export const Container = styled.div`
    min-height: 100vh;

    display: flex;
    flex-direction: column;

    background-image: linear-gradient(rgb(14 13 13 / 30%),rgb(0 0 0 / 32%)),url(/assets/images/student.svg);
    object-fit: cover;
    background-size: cover;

    padding-top: 4.5rem;
`;

export const Wrapper = styled.div`
    max-width: 1440px;
    width: 100%;
    
    display: flex;
    justify-content: center;

    margin: 0 auto;

    margin-top: 1rem;

    .section {
        display: flex;
        flex-direction: column;
        justify-content: center;

        align-items: center;

        padding: 2rem 4rem;

        border: 0.0625rem solid #dadce0;
        border-radius: 0.8rem;

        background: var(--gray-color);

        max-width: 35rem;
        height: 35rem;

        gap: 0.9rem;

        .title {
            font-family: 'Lexend', sans-serif;
            font-size: 1.5rem;
            font-weight: 500;
            color: var(--text-color);
        }

        .description {
            font-family: 'Lexend', sans-serif;
            font-size: 1rem;
            font-weight: 300;
            color: var(--text-color);

            text-align: center;
        }

        .button {
            display: flex;
            justify-content: center;
            
            padding: 1.1rem;

            border-style: none;
            border-radius: 0.2rem;

            text-decoration: none;

            background-color: var(--button-color);
            color: var(--white-color);
            
            font-family: 'Lexend', sans-serif;
            font-weight: 500;
            font-size: 1rem;

            cursor: pointer;

            &:hover {
                filter: brightness(0.9);
            }
        }

        @media screen and (max-width: 768px) {
            height: 100%;
            max-width: 100%;

            display: flex;
            justify-content: initial;

            padding: 2rem 3rem;

            .title {
                font-size: 1rem;
            }

            .description {
                font-size: 1rem;
            }
        }
    }

    @media screen and (max-width: 768px) {
        height: 100vh;

        display: flex;
        justify-content: center;

        margin-top: 0;

        .section {
            border-radius: 0;
        }
    }
`;