import { Container } from "./styles";
import CircularProgress from '@mui/material/CircularProgress';

export function Loading() {
    return (
        <Container>
            <div className="loading">
                <CircularProgress size={80} />
                <h2 className="title">Carregando..</h2>
            </div>
        </Container>
    );
};