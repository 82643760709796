import { useState } from "react";
import { Container, Wrapper } from "./styles";

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

import ViewAnnotation from "../ViewAnnotation";

import { DeleteAnnotation } from "../DeleteAnnotation";

interface IProps {
    id: string;
    title: string;
    content: string;
}

export function AnnotationCard({ id, title, content }: IProps) {
    const [viewAnnotation, setViewAnnotation] = useState(false);
    const [confirmAnnotationDelete, setConfirmAnnotationDelete] = useState(false);

    function handleClick() {
        setViewAnnotation(!viewAnnotation);
    }

    return (
        <Container>
            <Wrapper>
                <h2 className="title">{ title }</h2>
                
                <div className="content">
                    <img src="./assets/images/notebook.svg" className="note_image" alt="paper"/>

                    <div className="buttons">
                        <Button onClick={() => setConfirmAnnotationDelete(!confirmAnnotationDelete)} 
                            style={{ background: '#ee2315', color: '#fff', border: '#ee2315' }}
                            variant="outlined"
                            startIcon={<DeleteIcon />
                        }>
                            Apagar
                        </Button>

                        <Button onClick={handleClick} style={{ background: '#13bfc5', color: '#fff', border: '#13bfc5' }} variant="outlined" startIcon={<FolderOpenIcon/>}>
                            Abrir
                        </Button>
                    </div>
                </div>
            </Wrapper>

            { 
                confirmAnnotationDelete && <DeleteAnnotation id={id} />
            }

            {
                viewAnnotation && <ViewAnnotation title={title} content={content} />
            }
        </Container>
    )
}