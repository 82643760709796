import styled from "styled-components";

export const Container = styled.div`
    max-width: 1440px;
    height: 100%;

    margin: 0 auto;

    .upload_card {
        max-width: 50rem;
        height: 30rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        
        align-items: center;

        background: var(--white-color);
        border-radius: 0.4rem;

        transition: 1s ease;

        padding: 1.5rem 4rem;

        gap: 0.8rem;

        border-top: 4px solid var(--blue-color);

        .form {
            max-width: 100%;

            display: flex;
            justify-content: center;
            flex-direction: column;

            align-items: center;

            gap: 1rem;

            input {
                margin-left: 2rem;
            }
        }

        span {
            font-family: 'Lexend', sans-serif;
            font-size: 1.3rem;
            font-weight: 400;
            color: var(--text-color);
        }

        img {
            max-width: 12rem;
            padding: 1rem;
        }

        input {
            display: none;
        }

        .button {
            max-width: 100%;
            width: 14rem;

            height: 3rem;

            display: flex;
            justify-content: center;

            align-items: center;

            text-align: center;

            border-style: none;
            border-radius: 0.2rem;

            gap: 0.4rem;

            background: var(--button-color);
            color: var(--white-color);
            
            font-family: 'Lexend', sans-serif;
            font-weight: 500;
            font-size: 1rem;

            cursor: pointer;

            &:hover {
                filter: brightness(0.9);
                background: var(--blue-color);
            }
        }

        .import {
            background-color: #5b52f7;

            &:hover {
                filter: brightness(0.9);
                background-color: #5b52f7;
            }
        }

        @media screen and (max-width: 1080px) {
            width: 30rem;
            height: 30rem;

            display: flex;
            flex-direction: column;

            align-items: center;

            padding: 2rem 3rem;

            .button {
                padding: 0.9rem 0.7rem;
                gap: 0.2rem;

                font-size: 1rem;

                text-align: center;
            }
        }

        @media screen and (max-width: 768px) {
            max-width: 20rem;
        }
    }

    .main {
        max-width: 37rem;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        align-items: center;

        padding: 3rem 4rem;

        border: 0.0625rem solid #dadce0;
        border-radius: 0.8rem;

        background: var(--gray-color);


        gap: 0.9rem;

        .title {
            font-family: 'Lexend', sans-serif;
            font-size: 1.5rem;
            font-weight: 500;
            color: var(--text-color);
        }

        .description {
            font-family: 'Lexend', sans-serif;
            font-size: 1rem;
            font-weight: 300;
            color: var(--text-color);

            text-align: center;
        }

        .image_import {
            max-width: 100%;

            height: 3.5rem;

            display: flex;
            justify-content: center;

            align-items: center;

            text-align: center;

            border-style: none;
            border-radius: 0.2rem;

            gap: 0.4rem;

            padding: 1rem;

            background: var(--button-color);
            color: var(--white-color);
            
            font-family: 'Lexend', sans-serif;
            font-weight: 500;
            font-size: 1rem;

            cursor: pointer;

            input {
                display: none;
            }

            &:hover {
                filter: brightness(0.9);
                background: var(--blue-color);
            }

            @media screen and (max-width: 768px) {
                font-size: 0.8rem;
            }
        }

        @media screen and (max-width: 768px) {
            max-width: 20rem;

            display: flex;
            justify-content: center;

            padding: 2rem;

            .title {
                font-size: 1rem;
            }

            .description {
                font-size: 1rem;
            }
        }
    }
`;