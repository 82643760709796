/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState } from 'react';

import { api } from "../../services/axios";
import { toast } from "react-toastify";

import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import Box from '@mui/material/Box';

import { useAuth } from "../../hooks/useAuth";

interface IProps {
    id: string;
}

export function DeleteAnnotation({ id }: IProps) {
    const [open, setOpen] = useState(true);
    const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');

    const { setLoading } = useAuth();

    const handleClose = () => {
        setOpen(false);
    };

    const deleteAnnotation = async () => {
        setLoading(true);

        await api.delete(`/annotations/${id}`)
            .then(() => {
                toast.success('Anotação deletada com sucesso!');
                return setLoading(false);
            })
            .catch(err => {
                toast.error(err.response.data.message);
                setLoading(false);
            })
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
            >
                <Box sx={{ maxWidth: 400 }}>
                    <DialogTitle id="scroll-dialog-title" style={{ fontFamily: 'Roboto', fontWeight: 300 }}>Você tem certeza que deseja apagar essa anotação?</DialogTitle>
                    <DialogActions style={{ alignItems: 'center' }}>
                        <Button className="button" onClick={deleteAnnotation}>Sim</Button>
                        <Button className="button" onClick={handleClose}>Não</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
}