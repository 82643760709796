import styled from "styled-components";

export const Container = styled.div`
    min-height: 100vh;

    background-image: linear-gradient(rgb(14 13 13 / 30%),rgb(0 0 0 / 32%)),url(/assets/images/students.svg);
    object-fit: cover;
    background-size: cover;

    padding-top: 4.5rem;
`;

export const Main = styled.main`
    height: 100vh;
    max-width: 1440px;

    margin: 0 auto;

    @media screen and (max-width: 768px) {
        height: 100%;

        display: flex;
    }
`;

export const Section = styled.section`
    width: 100%;

    display: flex;
    justify-content: center;
    flex-direction: column;

    padding: 1.2rem 2.5rem;

    gap: 1rem;
    
    .welcome_message {
        display: flex;
        flex-direction: column;

        align-items: center;

        gap: 0.7rem;

        padding: 1rem 4rem;

        .title {
            font-family: 'Lexend', sans-serif;
            font-size: 1.5rem;
            font-weight: 400;
            color: var(--white-color);
        }

        .description {
            font-family: 'Lexend', sans-serif;
            font-size: 1rem;

            font-weight: 300;
            text-align: start;
            
            line-height: 1.4rem;

            color: var(--gray-color);
        }

        @media screen and (max-width: 768px) {
            display: flex;
            justify-content: center;

            padding: 1.3rem 1.5rem;

            .title {
                font-size: 1.3rem;
            }

            .description {
                width: 20rem;
                text-align: center;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;

        gap: 1rem;

        transition: 1s ease;

        .content_title {
            font-family: 'Roboto', sans-serif;
            
            font-size: 1.5rem;
            font-weight: 500;

            line-height: 2rem;

            border-bottom: 3px solid var(--blue-color);
            width: 17rem;
            padding-bottom: 0.5rem;
            margin-bottom: 0.2rem;
            
            color: var(--white-color);
        }

        .content_cards {
            display: flex;
            flex-wrap: wrap;

            gap: 2rem;
        }
    }

    .profile .profile_title {
        font-family: 'Roboto', sans-serif;
        
        font-size: 1.5rem;
        font-weight: 500;

        line-height: 2rem;

        border-bottom: 3px solid var(--blue-color);

        width: 17rem;
        
        padding-bottom: 0.5rem;
        margin-bottom: 0.2rem;
        
        color: var(--white-color);
    }

    @media screen and (max-width: 1440px) {
        padding: 1.3rem 2rem;

        transition: 0.3s ease;
    }

    @media screen and (max-width: 968px) {
        .content {
            display: flex;
            justify-content: center;
        }
    }
    
    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: center;

        .content {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }
`;

export const Grid = styled.div`
    display: flex;
    
    flex-wrap: wrap;

    padding: 1rem 3.5rem;
    
    gap: 2rem;

    @media screen and (max-width: 968px) {
        display: flex;
        justify-content: center;

        gap: 1.5rem;

        padding: 0;
    }
`;