/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { api } from "../../services/axios";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";

export function ViewPDF() {
    const navigate = useNavigate();
    
    const { id } = useParams();

    useEffect(() => {
        const materialData = async () => await api.get(`/materials/${id}`);

        materialData()
            .then((response) => {
                const data = response.data;

                localStorage.setItem('last_accessed_material', String(id));
                
                const url = data.file_url.split('material/')[1];

                return window.location.replace(`https://material.genmais.com.br/?data=${url}`);
            })
            .catch(() => {
                toast.error("Algo deu errado!");
                return navigate("/material");
            })
    }, []);

    return <Loading/>
}
